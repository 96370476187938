'use strict';
var angular = require('angular');
var cartService = angular.module('cartService', []);

var factory = function (app, localStorageService, $http) {
	var LOCALSTORAGE_KEY = "cartContainer";

	var instance = {
		cartContainer : null,
		productChunks : [],
		orderConfirmation : false,
		cartPopup : false,
		/**
		 * fetches current cart object
		 */
		getStoredCart : function(){
			if(localStorageService.get(LOCALSTORAGE_KEY) != null){
				this.cartContainer = localStorageService.get(LOCALSTORAGE_KEY);
				this.partition(this.cartContainer['products'], 2);
			}else{
				this.clearCart();
			}
		},

		clearCart : function(){
			this.cartContainer = {'products':{}, 'cartCount':0, 'subtotal':0, 'tax':0, 'totalPrice':0, 'digital':false};
			this.productChunks = [];
			localStorageService.remove(LOCALSTORAGE_KEY);
		},

		checkMixed : function(){
			var products = this.cartContainer['products'];
			var containsDigital = false;
			var containsPhysical = false;
			for(var key in products){
				if(products[key].facets.digital == "true"){
					containsDigital = true;
				}
				else {
					containsPhysical = true;
				}
			}
			if(containsDigital && containsPhysical){
				return true;
			}
			else {
				return false;
			}
		},

		/**
		 * recalculate current cart totals
		 */
		recalculateCart : function(){
			var products = this.cartContainer['products'];
			var cartCount = 0;
			var subtotal = 0;

			for(var p in products){
				if(products[p].cartQuantity <= 0){
					delete products[p];
				} else {
					// Calculate new cart count and total price
					var price = products[p].salePrice || products[p].price;
					cartCount = cartCount + products[p].cartQuantity;
					subtotal = subtotal + (products[p].cartQuantity * price);
				}
			}
			if(Object.keys(products).length == 0){
				cartCount = 0;
				subtotal = 0;
			}

			this.cartContainer['cartCount'] = cartCount;
			this.cartContainer['subtotal'] = Math.round(subtotal * 100) / 100;
			this.cartContainer['tax'] = Math.round(subtotal * 0.05 * 100) / 100;

			this.cartContainer['totalPrice'] = subtotal + this.cartContainer['tax'];

			localStorageService.set(LOCALSTORAGE_KEY, this.cartContainer);
			this.partition(this.cartContainer['products'], 2);
		},


		/**
		 * updates current cart object
		 */
		updateCart : function(product, quantity, productId){
			var products = this.cartContainer['products'];
			var cartCount = this.cartContainer['cartCount'];
			var subtotal = this.cartContainer['subtotal'];
			// If product already in cart, add to existing quantity
			// Otherwise, value is quantity
			if(products[productId] != null){
				products[productId].cartQuantity = products[productId].cartQuantity + quantity;
			}
			else {
				// clean product
				var keepers = ['productId','price','salePrice','name','image','facets','cartQuantity', 'attribute1'];
				var cleanProduct = {}
				for(var i in keepers){
					switch(keepers[i]){
						case 'facets':
							cleanProduct[keepers[i]] = {'digital': product[keepers[i]]['digital']}
							break;
						default:
							cleanProduct[keepers[i]] = product[keepers[i]]
					}
				}

				// Add passed product data to product list
				products[productId] = cleanProduct;
				products[productId].cartQuantity = quantity;
			}

			// Calculate new cart count and total price
			var price = products[productId].salePrice || products[productId].price;
			cartCount = cartCount + quantity;
			subtotal = subtotal + (quantity * price);

			// If less than 0 of given product
			// Remove from cart and product list
			if(products[productId].cartQuantity <= 0){
				delete products[productId];
				// If cart is empty, reset all cart data
				if(Object.keys(products).length == 0){
					cartCount = 0;
					subtotal = 0;
				}
			}

			// set digital cart status
			this.cartContainer.digital = true;
			for(var key in products){
				if(products[key].facets.digital != "true"){
					this.cartContainer.digital = false;
					break;
				}
			}

			// Update local storage cart container with local version
			this.cartContainer['products'] = products;
			this.cartContainer['cartCount'] = cartCount;
			this.cartContainer['subtotal'] = Math.round(subtotal * 100) / 100;
			this.cartContainer['tax'] = Math.round(subtotal * 0.05 * 100) / 100;

			this.cartContainer['totalPrice'] = subtotal + this.cartContainer['tax'];

			localStorageService.set(LOCALSTORAGE_KEY, this.cartContainer);
			this.partition(this.cartContainer['products'], 2);
		},

		partition : function(list, size){
			var listArray = Object.keys(list);
			var chunks = [];
			for (var i=0; i<listArray.length; i+=size) {
				chunks.push(listArray.slice(i, i+size));
			}
			this.productChunks = chunks;
		}
	};

	// initialize from local storage
	instance.getStoredCart();

	return instance;
};

cartService.factory('cart', ['app', 'localStorageService', '$http', factory]);
module.exports = cartService.name;
