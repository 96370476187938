'use strict';

var controller = function ($state, checkoutService, $window, configurationManager, logger, masterpassDataService, textBundle) {
	logger('returnCheckout controller');

	/* declaration */

	this.modes = [
			"billing",
			"shipping",
			"reward"
		];

	this.currentStep;

	this.nextStep;

	this.precheckoutData = configurationManager.state.preCheckoutData;

	this.walletPartnerLogoUrl = configurationManager.state.walletPartnerLogoUrl;

	this.masterpassLogoUrl = configurationManager.state.masterpassLogoUrl;

	this.model = {
			shipping : null,
			billing : null,
			reward : null
		}

	this.goToNextStep = goToNextStep;

	this.clearModelValue = clearModelValue;



	/* initialization */

	this.goToNextStep();



	/* implementation */

	function clearModelValue(key){
		this.model[key] = null;
	}

	/**
	 * Sets currentStep and nextStep or continues to review page when all steps are completed
	 */
	function goToNextStep(){
		this.nextStep = 'review';
		$window.scrollTo(0,0);
		var stepFound = false;
		if(this.precheckoutData.cards && !this.model.billing){
			if(!stepFound){
				this.currentStep = this.modes[0];
			}else{
				this.nextStep = this.modes[0];
				return;
			}
			stepFound = true;
		}
		if(this.precheckoutData.shippingAddresses && !this.model.shipping){
			if(!stepFound){
				this.currentStep = this.modes[1];
			}else{
				this.nextStep = this.modes[1];
				return;
			}
			stepFound = true;
		}
		if(configurationManager.state.rewards && this.precheckoutData.rewardPrograms && !this.model.reward){
			if(!stepFound){
				this.currentStep = this.modes[2];
			}else{
				this.nextStep = this.modes[2];
				return;
			}
			stepFound = true;
		}

		if(!stepFound){
			masterpassDataService.setMasterpassData({
					precheckoutCardId: this.model.billing,
					precheckoutShippingId: this.model.shipping,
					precheckoutLoyaltyId: this.model.reward == 'none' ? '' : this.model.reward
				},
				true);

			checkoutService.setMasterpassBranding(this.model, configurationManager.state.walletPartnerLogoUrl, configurationManager.state.masterpassLogoUrl);
			$state.go('review');
		}
	}
};

module.exports = function (module) {
	var name = 'ReturnCheckoutController';
	module.controller(name, controller);

	return name;
};