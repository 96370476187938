'use strict';
var angular = require('angular');
var ngResource = require('angular-resource');

var textBundleService = angular.module('textBundleService', [ngResource]);

textBundleService.factory('textBundle', function (logger, remoteTextBundle, $q) {

	var textBundle = [];

	return {
		getBundle: function (language) {
			if (textBundle[language] == null) {
				var defer = $q.defer();
				remoteTextBundle.get(language).then(function (result) {
					logger("retrieved remote text bundle");

					textBundle[language] = result;

					defer.resolve(result);
				});

				return defer.promise;
			} else {
				var defer = $q.defer();
				defer.resolve(textBundle[language]);
				return defer.promise;
			}
		}
	}
});

textBundleService.factory('remoteTextBundle', function ($http, logger, $q) {
	return {
		get: function (language) {
			var temp = {};

			var defer = $q.defer();
			$http.get('data/' + language + '/textBundle.json').success(function (data) {
				temp = data;

				defer.resolve(data);
			});

			return defer.promise;
		}
	}
});

module.exports = textBundleService.name;
