'use strict';

var controller = function (accountService, checkoutService, $state, $scope, $stateParams, $rootScope, $location, $timeout, $window, app, cart, config, logger, textBundle) {
	logger('account controller');

	/* declaration */

	var vm = this;

	this.app = app;

	this.logout = logout;

	this.login = login;

	this.updateAccount = updateAccount;

	this.createAccount = createAccount;

	this.accountService = accountService;

	this.checkoutService = checkoutService;

	this.changePassword = changePassword;

	this.submitForm = submitForm;

	this.states = null;

	this.region = null;

	this.regions = config.regions;

	this.tokenizationTypes = config.tokenizationTypes;

	this.source = config.mdes.source;

	this.accountScore = config.mdes.accountScore;

	this.recommendation = config.mdes.recommendation;

	this.recommendationReasons = config.mdes.recommendationReasons;

	this.user = {};

	this.userTypes = config.userTypes;

	this.newUser = {};

	this.passwords = {};

	this.errorMessage = "";

	this.successMessage = "";

	this.showError = false;

	this.config = config;

	this.state = $state;

	this.infoHolder = null;

	this.editCard = null;

	this.copyInfo = copyInfo;

	this.getOrders = getOrders;

	this.editCardInfo = {};

	this.setTab = setTab;

	this.animateScrollBottom = animateScrollBottom;

	this.animateScrollTop = animateScrollTop;

	this.addTimeZone = addTimeZone;

//	this.saveCard = saveCard;

	this.addCard = addCard;

	this.removeCard = removeCard;

	this.enrollFido = enrollFido;

	this.walletEmpty = true;

	this.suspendCard = suspendCard;

	this.unsuspendCard = unsuspendCard;

	this.changePrimary = changePrimary;

	this.getCardArt = checkoutService.getCardArt.bind(checkoutService);

	this.showAdvancedSettings = false;

	this.updateLastLogIn = updateLastLogIn;

	this.refundOrder = refundOrder;

	this.refundRegistrationFee = refundRegistrationFee;

	this.refundRecurringFee = refundRecurringFee;

	this.onCountryChange = onCountryChange;

	this.customTokenizationTypes = null;

	this.loadCustomTokenizationTypes = loadCustomTokenizationTypes;

	this.openCustomTokenizationTypes = openCustomTokenizationTypes;

	this.closeCustomTokenizationTypes = closeCustomTokenizationTypes;

	this.saveCustomTokenizationTypes = saveCustomTokenizationTypes;

	this.getTokenizationTypeDisplayName = getTokenizationTypeDisplayName;

	/* initialization */

	$rootScope.$on('$stateChangeSuccess', function(event, to, toParams, from, fromParams) {
		$rootScope.previousState = fromParams.tab;
		vm.previousState = from.name;
		vm.nextState = to.name;
	});

	if(accountService.accountId != null){
		vm.tab = $rootScope.previousState;
		$timeout(1).then(
			function(){
				if($stateParams.tab != null){
					vm.tab = $stateParams.tab;
					animateScrollTop();
					if(vm.tab == 3){
						getOrders(0, 3, "id");
					}
					else if(vm.tab == 2){
						getCards();
						loadCustomTokenizationTypes();
						if(accountService.accountInfo.primaryPromoviehub != null){
							accountService.showCards = true;
							accountService.showPrimary = true;
							accountService.editCardForm = false;
							accountService.addCardForm = false;
							accountService.expandCards = false;
						}
					}
				}
				else {
					if($state.current.name == "account"){
						setTab(1);
					}
					window.scrollTo(0, 0);
				}
			}
		);
	}
	else {
		$location.search({});
	}

	if($state.current.name == "createAccount" && accountService.accountId != null){
		$state.go("account");
	}



	/* implementation */

	function setTab(tab){
		$location.search({tab: tab});
	}

	function animateScrollBottom(){
		// $('html, body, .body').animate({
		// 	scrollTop: 100000
		// }, 500);
	}

	function animateScrollTop(){
		// $('html, body, .body').animate({
		// 	scrollTop: 0
		// }, 500);
	}

	function addTimeZone(timestamp){
		if (!timestamp.match(/[a-z]/i)) {
			var date = new Date(timestamp);
			return date.toGMTString();
		}
		return timestamp;

	}

	function getOrders(page, size, sort){
		accountService.getOrders(page, size, sort).then(
			function(orders){
				accountService.orders = orders;
				accountService.pageList = getPageList(2, orders.number, orders.totalPages);
				window.scrollTo(0, 0);
			},
			function(data){
				alert(data.message);
				$state.reload();
			}
		);
	}

	function onCountryChange(region1){
		var region = angular.fromJson(region1);
		this.editCard.address.state = undefined;
		if(region != null){
			if (region.name != null){
				this.editCard.address.country = region.value;
			}
			if(region.subdivisions != null){
				this.states = region.subdivisions;
			} else {
				this.editCard.address.state = undefined;
				this.states = null;
			}
		} else {
				this.editCard.address.country = null;
				this.states = null;
		}
	}

/*	function saveCard(scope, card){
		if(checkForm(scope, "editCard")){
			app.loading = true;
			if(card.primary == null){
				card.primary = false;
			}
			if(card.store == null){
				card.store = "promoviehub";
			}
			if(card.doNotIncludeName == null){
				card.doNotIncludeName = false;
			}
			if(card.doNotIncludeAddress == null){
				card.doNotIncludeAddress = false;
			}
			accountService.saveCard(card).then(
				function() {
					console.log("Card Save SUCCESS");
					vm.editCard = {};
					accountService.editCardForm = false;
					accountService.showPrimary = true;
					accountService.showCards = true;
					animateScrollTop();
					getCards();
				},
				function(data){
					alert(data.message);

					if(data.status == 401){
						$state.reload();
					}else{
						app.loading = false;
					}
				}
			);
		}
	}*/

	function addCard(scope, card){
		if(checkForm(scope, "addCard")){
			app.loading = true;
			card.name = card.address.first + ' ' + card.address.last;
			card.type = accountService.detectCardType(card.cardNumber);
			if(card.primary == null){
				card.primary = false;
			}
			if(card.doNotIncludeName == null){
				card.doNotIncludeName = false;
			}
			if(card.doNotIncludeAddress == null){
				card.doNotIncludeAddress = false;
			}
			if(card.nonTokenized == null){
				card.nonTokenized = false;
			}
			if(card.store == null){
				card.store = config.storeName;
			}
			if(card.decisioningData == null){
				card.decisioningData = {};
			}
			accountService.saveCard(card).then(
				function() {
					console.log("Card Add SUCCESS");
					vm.editCard = {};
					accountService.addCardForm = false;
					accountService.showPrimary = true;
					accountService.showCards = true;
					animateScrollTop();
					getCards();
				},
				function(data){
					alert(data.message);
					if(data.status == 401) {
						$state.reload();
					} else {
						app.loading = false;
					}
				}
			);
		}
	}

	function removeCard(card){
		var choice = confirm("Are you sure you wish to remove this card from your account?");
		if(choice){
			accountService.removeCard(card).then(
				function() {
					console.log("Card Removal SUCCESS");
					vm.editCard = {};
					getCards();
				},
				function(data){
					alert(data.message);
					$state.reload();
				}
			);
		}
	}

	function getCards(){
		accountService.getCards().then(
			function(cards){
				accountService.cards = cards;
				app.loading = false;
				accountService.init();
				if(cards.length == 0){
					accountService.addCardForm = true;
					accountService.showPrimary = false;
					accountService.showCards = false;
					vm.walletEmpty = true;
				}
				else {
					vm.walletEmpty = false;
				}
			},
			function(data){
				alert(data.message);
				$state.reload();
			}
		);
	}

	function enrollFido(card) {
		accountService.getFidoAuthenticationData(card).then(
			function (authReqData) {
				runEnroll(card, authReqData);
			},
			function (data) {
				alert("Failed to start MA-FIDO enroll");
			}
		);
	}

	function runEnroll(card, authReqData) {
		var payload = {
			"srcClientId": authReqData.srcClientId,
			"serviceId": authReqData.serviceId,
			"authenticationMethod": {
				"authenticationMethodType": "MANAGED_AUTHENTICATION",
				"methodAttributes": {
					"uriData": {
						"uri": card.managedAuthUri
					}
				}
			},
			"accountReference": {
				"srcDigitalCardId": authReqData.srcDigitalCardId
			},
			"authenticationContext": {
				"authenticationReasons": [
					"ENROL_FINANCIAL_INSTRUMENT"
				],
				"acquirerMerchantId": authReqData.acquirerMerchantId,
				"acquirerBIN": authReqData.acquirerBIN,
				"dpaTransactionOptions": {
					"transactionAmount": {
						"transactionAmount": "0",
						"transactionCurrencyCode": app.selectedCurrency.name
					}
				},
				"threeDsInputData": {
					"billingAddress": {
						"name": card.address.first + ' ' + card.address.last,
						"line1": card.address.line1,
						"line2": card.address.line2,
						"state": card.address.state,
						"zip": card.address.postalCode,
						"country": card.address.country
//						"countryCode": 'US'
					}
				}
			}
		};

		window.addEventListener('message', ({
			data,
			source,
			origin
		}) => {
			if (source !== popup) {
				console.log('Wrong Window');
				return // ensure it is the correct window
			}

			var trace = {
				"api": "FidoEnrollFinancialInstrument",
				"tokenType": card.tokenType,
				"endpoint": card.managedAuthUri,
				"request": JSON.stringify({
					action: "Authenticate",
					payload
				}),
				"store": "promoviehub",
				"suffix": card.lastFour,
			};
			switch (data.action) {
				case "Ready":
					// Auth UI has loaded and is ready to receive the initial payload
//					console.log(payload);
					(async () => {
						popup.postMessage({
							action: "Authenticate",
							payload
						}, origin)
					})()
					break;
				case "Success":
					// Auth UI has completed with success
					window.removeEventListener('message', this);
					popup.close()
//					console.log(data.payload);
					if (data.payload.assuranceData) {
						saveFidoAssuranceData(card, data.payload.assuranceData);
					}
					trace.response = JSON.stringify(data);
					accountService.saveTrace(trace);
					break;
				case "Error":
					// Auth UI has failed to perform authentication
					window.removeEventListener('message', this);
					popup.close()
//					console.log(data.payload);
					trace.response = JSON.stringify(data);
					accountService.saveTrace(trace);
					break;
			}
		});

		const popup = window.open(card.managedAuthUri + '?origin=https%3A%2F%2F' + window.location.host, 'fidoenrollpopup', 'width=400,height=640');
	}

	function saveFidoAssuranceData(card, assuranceData) {
//		console.log("assuranceData " + JSON.stringify(assuranceData));
		card.assuranceData = JSON.stringify(assuranceData);
		accountService.saveFidoAssuranceData(card).then(
			function () {
				console.log("MA-FIDO Enroll successfully completed");
			},
			function (data) {
				console.log("Failed to save MA-FIDO AssuranceData:" + data);
				alert("Failed to save MA-FIDO AssuranceData");
			}
		);
	}

	function loadCustomTokenizationTypes() {
		accountService.getCustomSrcTokenizationTypes().then(
			function (data) {
				console.log("CustomSrcTokenizationTypes:" + JSON.stringify(data));
				vm.customTokenizationTypes = data;
			},
			function (err) {
				console.log("Failed to get CustomSrcTokenizationTypes:" + err);
			}
		);
	}

	function openCustomTokenizationTypes() {
		document.getElementById('customTokenizations').style.display = 'block';
	}

	function closeCustomTokenizationTypes() {
		document.getElementById('customTokenizations').style.display = 'none';
	}

	function saveCustomTokenizationTypes() {
		console.log("In saveCustomTokenizationTypes" + JSON.stringify(vm.customTokenizationTypes));
		var ttypes = [];
		var invalidForm = false;
		if (vm.customTokenizationTypes && vm.customTokenizationTypes.length > 0) {
			for (var ot of vm.customTokenizationTypes) {
				if (!document.getElementById('ctt_delete_id_' + ot.id).checked) {
					var tt = {};
					tt.id = ot.id;
					tt.tokenizationType = ot.tokenizationType;
					tt.serviceId = document.getElementById('ctt_serviceId_id_' + ot.id).value;
					var invalidServiceId = (tt.serviceId.trim().length == 0);
					document.getElementById('ctt_serviceId_id_' + ot.id).classList.toggle('ng-invalid', invalidServiceId);
					invalidForm = invalidForm || invalidServiceId;
					tt.srcDpaId = document.getElementById('ctt_srcDpaId_id_' + ot.id).value;
					tt.description = document.getElementById('ctt_description_id_' + ot.id).value;
					tt.isAvailable = document.getElementById('ctt_isAvailable_id_' + ot.id).checked;
					ttypes.push(tt);
				}
			}
		}
		// add new type if provided
		if (document.getElementById('ctt_tokenizationType_id_new').value.trim().length > 0 || document.getElementById('ctt_serviceId_id_new').value.trim().length > 0) {
			var tt = {};
			tt.tokenizationType = 'SRC_' + document.getElementById('ctt_tokenizationType_id_new').value;
			var invalidTokenizationType = (tt.tokenizationType.trim().length == 4);
			document.getElementById('ctt_tokenizationType_id_new').classList.toggle('ng-invalid', invalidTokenizationType);
			invalidForm = invalidForm || invalidTokenizationType;

			tt.serviceId = document.getElementById('ctt_serviceId_id_new').value;
			var invalidServiceId = (tt.serviceId.trim().length == 0);
			document.getElementById('ctt_serviceId_id_new').classList.toggle('ng-invalid', invalidServiceId);
			invalidForm = invalidForm || invalidServiceId;

			tt.srcDpaId = document.getElementById('ctt_srcDpaId_id_new').value;
			tt.description = document.getElementById('ctt_description_id_new').value;
			tt.isAvailable = document.getElementById('ctt_isAvailable_id_new').checked;
			ttypes.push(tt);
		}

		if (invalidForm) {
			return;
		}
		console.log("In saveCustomTokenizationTypes" + JSON.stringify(ttypes));
		accountService.saveCustomSrcTokenizationTypes(ttypes).then(
			function (data) {
				console.log("Saved CustomSrcTokenizationTypes:" + JSON.stringify(data));
				closeCustomTokenizationTypes();
				$state.reload();
			},
			function (err) {
				console.log("Failed to Save CustomSrcTokenizationTypes:" + err);
			}
		);
	}

	function getTokenizationTypeDisplayName(cardTokType) {
		return accountService.getTokenizationTypeDisplayName(cardTokType);
	}

	function updateLastLogIn() {
		accountService.updateLastLogIn();
	}

	function getPageList(shownPages, currentPage, totalPages){
		var pageList = [];
		var left = currentPage - shownPages;
		var right = currentPage + shownPages;
		for(var i = left; i < currentPage; i++){
			if(i < 0){
				right ++;
			}
			else {
				pageList.push(i);
			}
		}
		pageList.push(currentPage);
		var leftovers = [];
		for(var i = currentPage + 1; i <= right; i++){
			if(i < totalPages){
				pageList.push(i);
			}
			else {
				leftovers.push(i)
			}
		}
		for(var page in leftovers){
			pageList.unshift(pageList[0] - 1);
		}
		return pageList;
	}

	function copyInfo(src, dest){
		this[dest] = angular.copy(src);
	}

	function submitForm(formName) {
		$rootScope.$broadcast('makeSubmit', {formName: formName})
	}

	function logout(){
		accountService.logout().then(
			function(){
				accountService.accountId = null;
				accountService.accountInfo = {};
				checkoutService.order.card = {};
				$state.reload();
			},
			function(){
				accountService.accountId = null;
				accountService.accountInfo = {};
				checkoutService.order.card = {};
				$state.reload();
			}
		);
	}

	function login(scope, user) {
		if(checkForm(scope, "loginForm")){
			accountService.login(user.username, user.password).then(
				function(){
					accountService.getAccountId().then(
						function(accountId) {
							updateLastLogIn();
							checkoutService.clearLastUsedCard();
							$state.reload();
						},
						function(error) {
							accountService.accountId = null;
							accountService.accountInfo = {};
							vm.errorMessage = error.message;
						}
					);
				},
				function(){
					accountService.accountId = null;
					accountService.accountInfo = {};
					vm.errorMessage = "Invalid Username or Password.";
				}
			);
		}
	}

	function createAccount(scope, user){
		if(checkForm(scope, "createAccountForm")){
			app.loading = true;
			accountService.createAccount(user).then(
				function() {
					vm.errorMessage = "";
					vm.successMessage = "Account was created successfully!";
					$window.scroll(0,0);
					var creds = {
						username: user.username,
						password: user.password
					};
					accountService.login(user.username, user.password).then(
						function(){
							updateLastLogIn();
							$state.reload();
						},
						function(){
							$state.reload();
						}
					);
				},
				function(data){
					vm.errorMessage = data.message;
					vm.successMessage = "";
					app.loading = false;
				}
			);
		}
	}

	function checkForm(scope, form) {
		return scope[form].$valid;
	}

	function changePassword(scope, passwords){
		if(checkForm(scope, "changePassword")){
			var change = confirm("Are you sure you wish to change your password? You will be automatically logged out.");
			if(change){
				accountService.changePassword(passwords).then(
					function() {
						console.log("Password Update SUCCESS");
						accountService.pwChange = false;
						vm.logout();
					},
					function(data){
						alert(data.message);
						$state.reload();
					}
				);
			}
		}
	}

	function updateAccount(scope, user){
		if(checkForm(scope, "modifyAccount")){
			accountService.updateAccount(user).then(
				function() {
					console.log("User Update SUCCESS");
					accountService.editInfo = false;
					$state.reload();
				},
				function(data){
					alert(data.message);
					$state.reload();
				}
			);
		}
	}

	function unsuspendCard(card){
		accountService.unsuspendCard(card)
			.then(function(){
				//$state.reload();
				getCards()
			},
			function(data){
				alert("Unsuspend failed: " + data.message);
			})
	}

	function suspendCard(card){
		accountService.suspendCard(card)
			.then(function(){
				//$state.reload();
				getCards()
			},
			function(data){
				alert("Suspend failed: " + data.message);
			})
	}

	function changePrimary(card){
		card.primary = true;
		accountService.changePrimary(card)
			.then(function(){
				//$state.reload();
				getCards()
			},
			function(data){
				alert(data.message);
			})
	}

	function refundOrder(orderId){
		checkoutService.refundOrder(orderId)
			.then(function(){
				$state.reload();
			},
			function(data){
				alert(data.message);
			})
	}

	function refundRegistrationFee(orderId){
		checkoutService.refundRegistrationFee(orderId)
			.then(function(){
				$state.reload();
			},
			function(data){
				alert(data.message);
			})
	}

	function refundRecurringFee(orderId){
		checkoutService.refundRecurringFee(orderId)
			.then(function(){
				$state.reload();
			},
			function(data){
				alert(data.message);
			})
	}

};

module.exports = function (module) {
	var name = 'AccountController';
	module.controller(name, controller);

	return name;
};
