'use strict';
var angular = require('angular');
var appService = angular.module('appService', []);

var factory = function (config, localStorageService, userSessionStore) {
	var LANGUAGE_KEY = 'selectedLanguage';
	var CURRENCY_KEY = 'selectedCurrency';
	var instance = {
		title: config.title,
		loading : false,
		showConfiguration : false,
		showTrace : false,
		selectedCurrency : config.currencies[0],
		selectedLanguage : userSessionStore.get(LANGUAGE_KEY) || config.constants.LANGUAGES[0].value,
		
		getSelectedCurrency : function(){
			if(localStorageService.get(CURRENCY_KEY) != null){
				this.selectedCurrency = localStorageService.get(CURRENCY_KEY);
			}
		},
		updateSelectedCurrency : function(currency){
			this.selectedCurrency = currency;
			localStorageService.set(CURRENCY_KEY, this.selectedCurrency);
		},

	};

	// initialize from local storage
	instance.getSelectedCurrency();

	return instance;

};

appService.factory('app', ['config', 'localStorageService', 'userSessionStore', factory]);
module.exports = appService.name;
