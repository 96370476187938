'use strict';

var controller = function ($sce, $state, $stateParams, $scope, $window, accountService, channel, cart, app, config, logger, textBundle, $timeout, $interval) {
	logger('channel controller');

	/* declaration */
	var vm = this;
	this.state = $state;
	this.stateParams = $stateParams;
	this.accountService = accountService;
	this.channel = channel;
	this.app = app;
	this.confirmRedirect = confirmRedirect;
	this.signinRedirect = signinRedirect;

	/* initialization */

	// Redirect to home page if masterpass user
	if(accountService.masterpassUser){
		$state.go('home');
	}

	function confirmRedirect() {
		if (accountService.accountId == null) {
			var choice = confirm("You must be signed in to proceed with subscription. Would you like to sign in or create an account?");
			if (choice) {
				$state.go('checkout',{channelId: vm.channel.channelId});
			}			
		}
		else if (accountService.accountInfo.primaryPromoviehub == null){
			var choice = confirm("There is no active payment method associated with your account. Do you want to add a card to your account?");
			if (choice) {
				$state.go('account', {tab: 2});
			}					
		}
		else {
			$state.go('checkout',{channelId: vm.channel.channelId});
		}
	}

	function signinRedirect() {
			$state.go('checkout',{channelId: vm.channel.channelId});
	}
/*	this.accessories = product.attribute3;

	// Accessories which happen to also be in the product list
	this.matchingAccessories = [];

	this.text = 1;

	this.channel = channel;

	this.description = $sce.trustAsHtml(this.channel.description);

	this.name = $sce.trustAsHtml(this.product.name);

	this.channelInCart = channelInCart;

	this.addToCart = addToCart;

	this.attribute = '';

	this.cart = cart;




	var makeActive, removeActive, makeHidden, initSlick;

	if(categoryService.categoryPath == null || categoryService.categoryPath.length == 0){
		categoryService.categoryPath = product.catId;
	}



	function channelInCart(){
		if(channel.channelId in cart.cartContainer.channels){
			return true;
		}
	}

	function addToCart() {
		cart.updateCart(this.channel, 1, this.channel.channelId);
	}*/
};


module.exports = function (module) {
	var name = 'ChannelController';
	module.controller(name, controller);

	return name;
};