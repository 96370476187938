module.exports = function (module) {
	var accountController = require('./modules/account')(module);
	var cartController = require('./modules/cart')(module);
	var categoryController = require('./modules/category')(module);
	var channelController = require('./modules/channel')(module);
	var channelListingController = require('./modules/channelListing')(module);
	var checkoutController = require('./modules/checkout')(module);
	var altIdCheckoutController = require('./modules/altIdCheckout')(module);
	var fpanCheckoutController = require('./modules/fpanCheckout')(module);
	var reviewController = require('./modules/review')(module);
	var altIdReviewController = require('./modules/altIdReview')(module);
	var confirmController = require('./modules/confirm')(module);
	var configurationController = require('./modules/configuration')(module);
	var homeController = require('./modules/home')(module);
	var loaderController = require('./modules/loader')(module);
	var topNavbarController = require('./modules/topNavbar')(module);
	var productController = require('./modules/product')(module);
	var productListingController = require('./modules/productListing')(module);
	var productsController = require('./modules/products')(module);
	var returnCheckoutController = require('./modules/returnCheckout')(module);
	var bottomNavbarController = require('./modules/bottomNavbar')(module);
	var traceController = require('./modules/trace')(module);
	var websiteLoginController = require('./modules/websiteLogin')(module);
	var checkoutRedirectController = require('./modules/checkoutRedirect')(module);
	var masterpassCallbackController = require('./modules/masterpassCallback')(module);
	var masterpassCheckoutController = require('./modules/masterpassCheckout')(module);

	module.config(function ($stateProvider, $urlRouterProvider) {

		$urlRouterProvider
			.when('/', '/home')
			.when('', '/home')

		$stateProvider
			.state('products', {
				url: '?:catId&:searchTerms&:products&:page&:size&:sort&:facets',
				templateUrl: 'tmpl/products.html',
				resolve: {
					facetResource: 'FacetData',
					facets: function(facetResource, $stateParams){
						return facetResource.get({
							catId: $stateParams.catId,
							searchTerms: $stateParams.searchTerms
						}).$promise;
					},
					categoryPathResource: 'CategoryPath',
					categoryPath: function(categoryPathResource, $stateParams){
						if($stateParams.catId){
							return categoryPathResource.get({
								catId: $stateParams.catId
							}).$promise;
						}
						else {
							return null;
						}
					}
				},
				controller: productsController,
				controllerAs: 'productsCtrl'
			})
			.state('products.productListing', {
				url: '/products',
				templateUrl: 'tmpl/productListing.html',
				resolve: {
					catalogResource: 'CatalogData',
					catalog: function(catalogResource, $stateParams){
						return catalogResource.get({
							catId: $stateParams.catId,
							page: $stateParams.page,
							size: $stateParams.size,
							sort: $stateParams.sort,
							searchTerms: $stateParams.searchTerms,
							facets: $stateParams.facets
						}).$promise;
					}
				},
				controller: productListingController,
				controllerAs: 'productListingCtrl'
			})
			.state('product', {
				url: '/product?:productId',
				templateUrl: 'tmpl/product.html',
				resolve: {
					productResource: 'ProductData',
					product: function(productResource, $stateParams){
						return productResource.get({ productId: $stateParams.productId}).$promise;
					}
				},
				controller: productController,
				controllerAs: 'productCtrl'
			})
			.state('channels', {
				url: '/channels',
				templateUrl: 'tmpl/channelListing.html',
				resolve: {
					channelListResource: 'ChannelListData',
					channelList: function(channelListResource){
						return channelListResource.get({}).$promise;
					}
				},
				controller: channelListingController,
				controllerAs: 'channelListingCtrl'
			})
			.state('channel', {
				url: '/channel?:channelId',
				templateUrl: 'tmpl/channel.html',
				resolve: {
					channelResource: 'ChannelData',
					channel: function(channelResource, $stateParams){
						return channelResource.get({ channelId: $stateParams.channelId}).$promise;
					}
				},
				controller: channelController,
				controllerAs: 'channelCtrl'
			})
			.state('cart', {
				url: '/cart',
				templateUrl: 'tmpl/cart.html',
				controller: cartController,
				controllerAs: 'cartCtrl'
			})
			.state('home', {
				url: '/home',
				templateUrl: 'tmpl/home.html',
				controller: homeController,
				controllerAs: 'homeCtrl'
			})
			.state('category', {
				url: '/category?:catId',
				templateUrl: 'tmpl/category.html',
				resolve: {
					catId: function($stateParams){
						return $stateParams.catId;
					},
					categoryResource: 'CategoryData',
					category: function(categoryResource, $stateParams){
						return categoryResource.get({ catId: $stateParams.catId}).$promise;
					}
				},
				controller: categoryController,
				controllerAs: 'categoryCtrl'
			})
			.state('checkoutRedirect', {
				url: '/checkoutRedirect?:MD&:PaRes',
				templateUrl: 'tmpl/checkoutRedirect.html',
				resolve: {
					md: function($stateParams){
						return $stateParams.MD;
					},
					paRes: function($stateParams){
						return $stateParams.PaRes;
					},
				},
				controller: checkoutRedirectController,
				controllerAs: 'checkoutRedirectCtrl'
			})
			.state('checkout', {
				params: {channelId: null},
				url: '/checkout',
				templateUrl: 'tmpl/checkout.html',
				controller: checkoutController,
				controllerAs: 'checkoutCtrl',
				resolve: {
					channelId: function($stateParams){
						if ($stateParams.channelId != null) {
							return $stateParams.channelId;
						}
						else {
							return null;
						}
					},
					channelResource: 'ChannelData',
					channel: function(channelResource, $stateParams){
						if ($stateParams.channelId != null) {
							return channelResource.get({ channelId: $stateParams.channelId}).$promise;
						}
						else {
							return null;
						}

					}
				},
			})
			.state('altIdCheckout', {
				params: {channelId: null},
				url: '/altIdCheckout',
				templateUrl: 'tmpl/altIdCheckout.html',
				controller: altIdCheckoutController,
				controllerAs: 'altIdCheckoutCtrl',
				resolve: {
					channelId: function($stateParams){
						if ($stateParams.channelId != null) {
							return $stateParams.channelId;
						}
						else {
							return null;
						}
					},
					channelResource: 'ChannelData',
					channel: function(channelResource, $stateParams){
						if ($stateParams.channelId != null) {
							return channelResource.get({ channelId: $stateParams.channelId}).$promise;
						}
						else {
							return null;
						}

					}
				},
			})
			.state('fpanCheckout', {
				params: {channelId: null},
				url: '/fpanCheckout',
				templateUrl: 'tmpl/fpanCheckout.html',
				controller: fpanCheckoutController,
				controllerAs: 'fpanCheckoutCtrl',
				resolve: {
					channelId: function($stateParams){
						if ($stateParams.channelId != null) {
							return $stateParams.channelId;
						}
						else {
							return null;
						}
					},
					channelResource: 'ChannelData',
					channel: function(channelResource, $stateParams){
						if ($stateParams.channelId != null) {
							return channelResource.get({ channelId: $stateParams.channelId}).$promise;
						}
						else {
							return null;
						}

					}
				},
			})
			.state('review', {
				url: '/review',
				templateUrl: 'tmpl/review.html',
				controller: reviewController,
				controllerAs: 'reviewCtrl'
			})
			.state('altIdReview', {
				params: {altIdCheckoutResp: null},
				url: '/altIdReview',
				templateUrl: 'tmpl/altIdReview.html',
				controller: altIdReviewController,
				controllerAs: 'altIdReviewCtrl',
				resolve: {
					altIdCheckoutResp: function($stateParams){
						return $stateParams.altIdCheckoutResp;
					}
				}
			})
			.state('returnCheckout', {
				url: '/returnCheckout',
				templateUrl: 'tmpl/returnCheckout.html',
				controller: returnCheckoutController,
				controllerAs: 'returnCtrl'
			})
			.state('account', {
				url: '/account?:tab',
				templateUrl: 'tmpl/account.html',
				controller: accountController,
				controllerAs: 'accountCtrl'

			})
			.state('createAccount', {
				url: '/createAccount',
				templateUrl: 'tmpl/createAccount.html',
				controller: accountController,
				controllerAs: 'accountCtrl'
			})
			.state('confirm', {
				params: {order: null},
				url: '/confirm',
				templateUrl: 'tmpl/confirm.html',
				controller: confirmController,
				controllerAs: 'confirmCtrl',
				resolve: {
					order: function($stateParams){
						return $stateParams.order;
					}
				}
			})
			.state('configuration', {
				url: '/configuration',
				templateUrl: 'tmpl/configuration.html',
				controller: configurationController,
				controllerAs: 'configurationCtrl',
				resolve: {
					accountTokens: function($stateParams){
						return {hi:'there'};
					}
				}
			})
			.state('masterpassCallback', {
				url: '/masterpassCallback?:oauth_token&:mpstatus',
				templateUrl: 'tmpl/masterpassCallback.html',
				controller: masterpassCallbackController,
				controllerAs: 'masterpassCallbackCtrl'
				/*resolve: {
					masterpassParams: function($state, $location){
						var masterpassParams = $location.search();
						//$location.search({});
						return masterpassParams;
					},
					fg: function($state, $location){
						var fg = $location.href;
						//$location.search({});
						return fg;
					}
				}*/
			})
			.state('masterpassCheckout', {
				params: {channelId: null, channelResource: null, pairingId: null},
				url: '/masterpassCheckout',
				templateUrl: 'tmpl/masterpassCheckout.html',
				controller: masterpassCheckoutController,
				controllerAs: 'mpCheckoutCtrl',
				resolve: {
					channelId: function($stateParams){
						if ($stateParams.channelId != null) {
							return $stateParams.channelId;
						}
						else {
							return null;
						}
					},
					channelResource: 'ChannelData',
					channel: function(channelResource, $stateParams){
						if ($stateParams.channelId != null) {
							return channelResource.get({ channelId: $stateParams.channelId}).$promise;
						}
						else {
							return null;
						}

					},
					pairingId: function($stateParams){
						return $stateParams.pairingId;

					}
				/*	paymentData: function($stateParams){
						return $stateParams.paymentData;
					},
					isExpressCheckout: function($stateParams){
						console.log($stateParams.isExpressCheckout);
						return $stateParams.isExpressCheckout;
					},
					generationTime: function($stateParams){
						console.log($stateParams.generationTime);
						return $stateParams.generationTime;
					}*/
				}
			})
	});

   module.filter('split', function() {
		return function(input, splitChar, splitIndex) {
			return input.split(splitChar)[splitIndex];
		}
	})

	.filter('encode', function() {
		return function(value){
			return encodeURIComponent(value);
		};
	})

	.filter('nospace', function () {
		return function (value) {
			return (!value) ? '' : value.trim();
		};
	})

	.filter('decode', function() {
		return function(value){
			return decodeURIComponent(value);
		};
	})

	.filter('capitalize', function() {
		return function(input, all) {
			var reg = (all) ? /([^\W_]+[^\s-]*) */g : /([^\W_]+[^\s-]*)/;
			return (!!input) ? input.replace(reg, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : '';
		}
	})

	.filter('camelCaseToHuman', function() {
	return function(input) {
			return input.charAt(0).toUpperCase() + input.substr(1).replace(/[A-Z]/g, ' $&');
		}
	})

	.filter('traceUrl', function() {
		return function(input) {
				try{
					var url = new URL(input);
					var result = '<span class=location>' + url.pathname;
					if(url.search){
						result += url.search;
					}
					if(url.hash){
						result += url.hash;
					}
					result += '</span>';
					result += '<span class=hostname>' + url.origin + '</span>'
					return result;
				}catch(err){
					return input;
				}
			}
	})

	.filter('parameters', function() {
		return function(input) {
				try{
					var parameterList = []
					if(input.indexOf('?') != -1){
						// discard before query start
						input = input.substring(input.indexOf('?'))
					}
					//break up individual parameters
					parameterList = input.split('&')

					// beautify
					if(parameterList[0][0] != '?'){
						parameterList[0] = '&' + parameterList[0];
					}
					for(var i = 1; i < parameterList.length; i++){
						parameterList[i] = '&' + parameterList[i];
					}

					var output = parameterList.join('\n')
					return output;
				}catch(err){
					return input;
				}
			}
	})

	.filter('sanitizeAsHtml', function($sce) {
		return $sce.trustAsHtml;
	})

	.filter('numberFixedLen', function () {
		return function (n, len) {
			var num = parseInt(n, 10);
			len = parseInt(len, 10);
			if (isNaN(num) || isNaN(len)) {
				return n;
			}
			num = ''+num;
			while (num.length < len) {
				num = '0'+num;
			}
			return num;
		};
	})

	.filter('time', function() {

		var conversions = {
		  'ss': angular.identity,
		  'mm': function(value) { return value * 60; },
		  'hh': function(value) { return value * 3600; }
		};

		var padding = function(value, length) {
		  var zeroes = length - ('' + (value)).length,
			  pad = '';
		  while(zeroes-- > 0) pad += '0';
		  return pad + value;
		};

		return function(value, unit, format, isPadded) {
		  var totalSeconds = conversions[unit || 'ss'](value),
			  hh = Math.floor(totalSeconds / 3600),
			  mm = Math.floor((totalSeconds % 3600) / 60),
			  ss = totalSeconds % 60;

		  format = format || 'hh:mm:ss';
		  isPadded = angular.isDefined(isPadded)? isPadded: true;
		  hh = isPadded? padding(hh, 2): hh;
		  mm = isPadded? padding(mm, 2): mm;
		  ss = isPadded? padding(ss, 2): ss;

		  return format.replace(/hh/, hh).replace(/mm/, mm).replace(/ss/, ss);
		};
	})

	.factory('CatalogData', function ($resource, config){
		return $resource(config.serviceBase + config.catalogBase + config.getProducts, {
			catId: '@_catId',
			page: '@_page',
			size: '@_size',
			sort: '@_sort',
			searchTerms: '@_searchTerms',
			facets: '@_facets',
			trim: true
		});
	})

	.factory('FacetData', function ($resource, config){
		return $resource(config.serviceBase + config.catalogBase + config.getFacets, {
			catId: '@_catId',
			searchTerms: '@_searchTerms'
		});
	})

	.factory('ProductData', function ($resource, config){
		return $resource(config.serviceBase + config.catalogBase + config.getProduct, {
			productId: '@_productId'
		});
	})

	.factory('CategoryData', function ($resource, config){
		return $resource(config.serviceBase + config.catalogBase + config.getCategory, {
			catId: '@_catId'
		});
	})

	.factory('CategoryPath', function ($resource, config){
		return $resource(config.serviceBase + config.catalogBase + config.getCategoryPath, {
			catId: '@_catId'
		});
	})

	.factory('CategoryList', function ($resource, config){
		return $resource(config.serviceBase + config.catalogBase + config.getCategoryList);
	})

	.factory('ChannelData', function ($resource, config){
		return $resource(config.serviceBase + config.catalogBase + config.getChannel, {
			channelId: '@_channelId'
		});
	})

	.factory('ChannelListData', function ($resource, config){
		return $resource(config.serviceBase + config.catalogBase + config.getChannels);
	})

	.directive('superImageSizer', function() {
		return {
			restrict: 'A',
			link: function(scope, elem, attr) {
				elem.on('load', function() {
					var w = $(this).width();
					var h = $(this).height();
					if (w > h) {
						elem.css({
							'width': '100%',
							'height': 'auto'
						});
					} else if (h > w) {
						elem.css({
							'width': 'auto',
							'height': '100%'
						});
					}
				});
			}
		};
	})

	.directive('pwCheck', [function() {
		return {
			require: 'ngModel',
			link: function(scope, elem, attrs, ctrl) {
				var firstPassword = '#' + attrs.pwCheck;
				elem.add(firstPassword).on('keyup', function() {
					scope.$apply(function() {
						var v = elem.val() === $(firstPassword).val();
						ctrl.$setValidity('pwmatch', v);
					});
				});
			}
		}
	}])

	.directive("extSubmit", ['$timeout',function($timeout){
		return {
			link: function($scope, $el, $attr) {
				$scope.$on('makeSubmit', function(event, data){
				  if(data.formName === $attr.name) {
					$timeout(function() {
					  $el.triggerHandler('submit'); //<<< This is Important
					  //$el[0].dispatchEvent(new Event('submit')) //equivalent with native event
					}, 0, false);
				  }
				})
			}
		};
	}]);

	module.run(function($rootScope){

	});
};
