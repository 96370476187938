'use strict';

var controller = function (accountService, app, cart, config, checkoutService, localStorageService, logger, $state, textBundle, $window) {
	logger('cart controller');

	/* declaration */

	var vm = this;
	this.accountService = accountService;

	this.cart = cart;

	this.app = app;

	this.checkoutService = checkoutService;

	this.state = $state;

	this.getCustomerName = accountService.getName.bind(accountService);

	this.checkout = checkout;

	this.quantityMod = quantityMod;

	this.recalculateCart = recalculateCart;

	this.confirmRedirect = confirmRedirect;

	this.confirmAltIdRedirect = confirmAltIdRedirect;

	this.confirmFpanRedirect = confirmFpanRedirect;

	this.masterpass = masterpass;

	this.pairingId = null;

	this.disableExpress = disableExpress;

	this.getPairingId = getPairingId;

	this.transactionType = null;

	this.mpTransactionType = config.masterpassTransactionType;

	/* initialization */

	getPairingId();

	/* implementation */

	function checkout(){
		checkoutService.setInfo(checkoutService.info);
		checkoutService.startCheckout();
	}

	function quantityMod(productId, value) {
		// Update quantity of cart item with value passed by template
		cart.updateCart(cart.cartContainer.products[productId], value, productId);
	}

	function recalculateCart() {
		cart.recalculateCart();
	}

	function confirmRedirect() {
		if (accountService.accountId == null){
			var choice = confirm("You must be signed in to proceed with checkout.  Would you like to sign in or create an account?");
			if (choice){
				$state.go('checkout');
			}
		}
		else if (accountService.accountInfo.primaryPromoviehub == null){
			var choice = confirm("There is no active payment method associated with your account. Do you want to add a card to your account?");
			if (choice){
				$state.go('account', {tab: 2});
			}
		}
		else {
			$state.go('checkout');
		}
	}

	function confirmAltIdRedirect() {
		if (accountService.accountId == null){
			var choice = confirm("You must be signed in to proceed with checkout.  Would you like to sign in or create an account?");
			if (choice){
				$state.go('altIdCheckout');
			}
		}
		else {
			$state.go('altIdCheckout');
		}
	}

	function confirmFpanRedirect() {
		if (accountService.accountId == null){
			var choice = confirm("You must be signed in to proceed with checkout.  Would you like to sign in or create an account?");
			if (choice){
				$state.go('fpanCheckout');
			}
		}
		else {
			$state.go('fpanCheckout');
		}
	}

	function masterpass(){
		if (vm.pairingId == null) {
			var cartDetails = {};
			var randNum = Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000;
			cartDetails.amount = (cart.cartContainer.totalPrice).toFixed(2).toString();
			cartDetails.currency = app.selectedCurrency.name;
			cartDetails.cartId = accountService.accountInfo.username + "-" + new Date().getTime() + "-" + randNum;
			cartDetails.transactionType = vm.transactionType;
			localStorageService.set('cartId', cartDetails.cartId);
			checkoutService.startMasterpassCheckout(cartDetails);
		} else {
			$state.go('masterpassCheckout',{"pairingId": vm.pairingId});
		}
	}

	function getPairingId(){
		if(accountService.masterpassUser){
			accountService.getPairingId().then(
				function(response) {
					vm.pairingId = response.pairingId;
				}, function(data) {

				}
			)
		}
	}

	function disableExpress(){
		checkoutService.disableExpressCheckout().then(
			function(response) {
				$state.reload();
			},
			function(data){
				alert("Could not disable express checkout");
				$state.reload();
			}
		)
	}

};

module.exports = function (module) {
	var name = 'CartController';
	module.controller(name, controller);

	return name;
};
