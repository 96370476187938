'use strict';

var controller = function ($state, app, config, logger, textBundle) {
	logger('home controller');

	/* declaration */

	this.app = app;

	this.imageLocation = config.homePageImages.url;

	this.links = config.homePageImages;

	this.TandC = config.TandC;

	/* initalization */

	/* implementation */
};

module.exports = function (module) {
	var name = 'HomeController';
	module.controller(name, controller);
	return name;
};