'use strict';
var angular = require('angular');
var loggerService = angular.module('loggerService', []);

var factory = function ($log) {
	return function (message) {

		var date = new Date();
		var timestamp = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '-' + date.getMilliseconds();

		$log.log(timestamp + ' - ', message);
	};
};

// build and export
loggerService.factory('logger', factory);
module.exports = loggerService.name;