'use strict';

var controller = function (category, categoryService, catId, config, logger, textBundle) {
	logger('category controller');

	/* declaration */

	this.imageLocation = config.categoryImageLocation + encodeURIComponent(catId);

	this.category = category;

	this.categoryService = categoryService;

	/* initialization */

	this.categoryService.currentCategory = catId;

	/* implementation */
};

module.exports = function (module) {
	var name = 'CategoryController';
	module.controller(name, controller);

	return name;
};