'use strict';

var controller = function ($scope, $state, $timeout, $window, app, config, logger, testingService, textBundle, configurationManager) {
	logger('configuration controller');

	/* declaration */


	this.state = $state;

	this.app = app;

	this.config = config;

	this.configurationManager = configurationManager;

	this.toggle = toggle;

	this.save = configurationManager.save.bind(configurationManager);

	this.clear = configurationManager.clear.bind(configurationManager);

	this.setPage = setPage;

	this.openTrace = openTrace;

	this.buyButton = buyButton;

	this.connectButton = connectButton;

	this.precheckoutDataButton = precheckoutDataButton;

	this.clearPairingInformation = clearPairingInformation;

	this.changeEnvironment = changeEnvironment;

	this.reloadJavascriptEnvironment = configurationManager.reloadJavascriptEnvironment.bind(configurationManager);

	this.reset = reset;

	this.updateReturnCheckoutButton = configurationManager.updateReturnCheckoutButton.bind(configurationManager);

	this.isConnectedCheckout = configurationManager.isConnectedCheckout.bind(configurationManager);

	this.generateDisplayAddress = generateDisplayAddress;

	this.generateDisplayCard = generateDisplayCard;

	this.generateDisplayReward = generateDisplayReward;

	//this.quickCart = quickCart;

	this.longAccessTokenUpdated = precheckoutDataButton;

	this.reloadShippingProfiles = reloadShippingProfiles;

	this.toggleArrayValue = toggleArrayValue;

	this.toggleCardArrayValue = toggleCardArrayValue;

	this.versionUpdated = versionUpdated;

	this.handleLoyaltyProgramChange = handleLoyaltyProgramChange;

	this.precheckoutSelectionChanged = precheckoutSelectionChanged;

	this.precheckoutUpdateTimer;

	this.transactTest = {
		name: 'Transact Test',
		run: runTransactTest,
		tuid: "",
		result: null,
		running: false
	};

	this.accountTokens = {
		name: 'Account Tokens',
		run: runAccountTokens,
		result: null,
		running: false
	};


	/* initialization */


	var vm = this;

	// watch opening of configuration screen to create return checkout button
	
	$scope.$watch(
		function watchShowConfiguration(scope){
			return app.showConfiguration;
		},
		function handleShowConfigurationChange(newValue, oldValue){
			if(newValue == true){
				vm.updateReturnCheckoutButton(true);
			}
		}
	);
	



	/* implementation */

	function versionUpdated(){
		if(configurationManager.state.checkoutType == config.masterpass.constants.CHECKOUT_TYPE.CONNECT){
			if(configurationManager.state.xmlVersion == 'v6'){
				// re-add button
				this.updateReturnCheckoutButton();
			}
		}
	}

	function reloadShippingProfiles(){
		configurationManager.getShippingProfiles(configurationManager.state.checkoutId);
	}

	function toggleArrayValue(value, array, preventRemovingLastItem){
		var index = array.indexOf(value);
		if(index > -1){
			// remove from array
			if(array.length == 1 && preventRemovingLastItem == true){
				// don't remove
			}else{
				array.splice(index, 1);
			}
		}else{
			// add to array
			array.push(value);
		}
		configurationManager.save();
	}

	function handleLoyaltyProgramChange(){
		if(configurationManager.state.allowedLoyaltyPrograms.length == 0){
			configurationManager.state.rewards = false;
		}
	}

	function toggleCardArrayValue(value, array, preventRemovingLastItem){
		var index = array.indexOf(value);
		if(index > -1){
			// remove from array
			var other = configurationManager.state.acceptedCards == array ? configurationManager.state.privateLabelCardTypes : configurationManager.state.acceptedCards;
			if(array.length == 1 && preventRemovingLastItem == true && other.length == 0){
				// don't remove
			}else{
				array.splice(index, 1);
			}
		}else{
			// add to array
			array.push(value);
		}
		configurationManager.save();
	}

	function toggle(){
		app.showConfiguration = !app.showConfiguration;
		if(app.showConfiguration == false){
			vm.save();
		}
	}

	function setPage(page){
		configurationManager.page = page;
	}

	function openTrace(){
		app.showConfiguration = false;
		this.save();

		app.showTrace = true;
	}

	function connectButton(){
		configurationManager.save();
		configurationManager.performConnect()
	}

	function buyButton(){
		configurationManager.save();
		if(configurationManager.state.xmlVersion != 'v5' 
				&& configurationManager.state.checkoutType == config.masterpass.constants.CHECKOUT_TYPE.EXPRESS
				&& configurationManager.state.longAccessToken)
		{
			configurationManager.performExpressCheckout();
		}else{
			// perform standard checkout
			configurationManager.performCheckout();
		}
	}

	function precheckoutDataButton(){
		configurationManager.save();
		if(configurationManager.state.longAccessToken){
			configurationManager.performPrecheckoutData();
		}
	}

	function clearPairingInformation(){
		masterpass.clearPairingData();
		masterpass.clearCheckoutData();
	}

	function changeEnvironment(){
		app.loading = true;
		configurationManager.loadEnvironment(
			configurationManager.state.environment,
			function(){
				app.loading = false;
			}
		);
	}

	function reset(){
		app.loading = true;
		configurationManager.reset(function(){
			app.loading = false;
		});
	}

	function generateDisplayAddress(address){
		var displayString = '';

		displayString += address.line1;
		// address.recipientName
		if(address.line2){
			displayString += ', ' + address.line2;
		}
		if(address.line3){
			displayString += ', ' + address.line3;
		}
		if(address.city){
			displayString += ', ' + address.city;
		}
		if(address.countrySubdivision){
			var arr = address.countrySubdivision.split('-');
			displayString += ', ' + arr[arr.length-1];
		}
		if(!address.city || !address.countrySubdivision){
			displayString += ', ' + address.country;
		}
		if(address.postalCode){
			displayString += ' ' + address.postalCode;
		}
		if(address.city && address.countrySubdivision){
			displayString += ' ' + address.country;
		}
		return displayString;
	}

	function generateDisplayCard(card){
		return (card.brandName == null ? '' : card.brandName) + ' ***-' + card.lastFour;
	}

	function generateDisplayReward(reward){
		return reward.rewardName;
	}

	function precheckoutSelectionChanged(){
		var self = this
		if(self.precheckoutUpdateTimer){
			// cancel old timer
			$timeout.cancel(self.precheckoutUpdateTimer)
		}
		self.precheckoutUpdateTimer = $timeout(
			function(){
				self.precheckoutUpdateTimer = null
				self.updateReturnCheckoutButton()
			},
			1000
		)
	}


	function runTransactTest(){
		var self = this;
		self.result = null;
		self.running = true;

		testingService.transactTest(self.tuid)
			.then(function(data){
				self.result = data;
			},
			function(data){
				self.result = {errorResponse: data};
			})
			.finally(function(){
				self.running = false;
			})
	}

	function runAccountTokens(){
		var self = this;
		self.result = null;
		self.running = true;

		testingService.getTokens()
			.then(function(data){
				self.result = data;
			},
			function(data){
				self.result = {errorResponse: data};
			})
			.finally(function(){
				self.running = false;
			})
	}
};

module.exports = function (module) {
	var name = 'ConfigurationController';
	module.controller(name, controller);

	return name;
};
