'use strict';

var controller = function ($sce, $state, $scope, $window, app, cart, categoryService, logger, product, ProductData, textBundle, $timeout, $interval) {
	logger('product controller');

	/* declaration */

	this.state = $state;

	this.accessories = product.attribute3;

	// Accessories which happen to also be in the product list
	this.matchingAccessories = [];

	this.text = 1;

	this.app = app;

	this.product = product;

	this.description = $sce.trustAsHtml(this.product.description);

	this.name = $sce.trustAsHtml(this.product.name);

	this.addToCart = addToCart;

	this.attribute = '';

	this.cart = cart;

	this.productInCart = productInCart;

	var vm = this;

	/* initalization */

	var makeActive, removeActive, makeHidden, initSlick;

	if(categoryService.categoryPath == null || categoryService.categoryPath.length == 0){
		categoryService.categoryPath = product.catId;
	}

	/* implementation */

	function productInCart(){
		if(product.productId in cart.cartContainer.products){
			return true;
		}
	}

	function addToCart() {
		cart.updateCart(this.product, 1, this.product.productId);
	}
};


module.exports = function (module) {
	var name = 'ProductController';
	module.controller(name, controller);

	return name;
};