'use strict';
var angular = require('angular');
var uiRouter = require('angular-ui-router');
var angularAnimate = require('angular-animate');
var localStorage = require('angular-local-storage');
var appService = require('./services/appService');
var testingService = require('./services/testService');
var configurationState = require('./services/configurationState');
var configurationManager = require('./services/configurationManager');
var accountService = require('./services/accountService');
var cartService = require('./services/cartService');
var checkoutService = require('./services/checkoutService');
var altIdCheckoutService = require('./services/altIdCheckoutService');
var fpanCheckoutService = require('./services/fpanCheckoutService');
var categoryService = require('./services/categoryService');
var core = require('../core');


var starter = angular.module('starter', [
		accountService,
		appService,
		testingService,
		cartService,
		categoryService,
		checkoutService,
		altIdCheckoutService,
		fpanCheckoutService,
		configurationManager,
		configurationState,
		core,
		uiRouter,
		angularAnimate,
		localStorage
	]);

var $http = angular.injector(["ng"]).get("$http");

fetchConfig().then(bootstrapApplication);




function fetchConfig() {
	return $http.get("data/config.json").then(function(response) {
		starter.constant("config", response.data);

	}, function(errorResponse) {
		alert('ERROR: CONFIG FILE NOT FOUND');
	});
}

function bootstrapApplication() {
	angular.element(document).ready(function() {
		var angularInjector = angular.bootstrap(document, ["starter"]);
	});
}

function setDefaultSdk(){
	var newurl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? (':' + window.location.port) : '') +
			window.location.pathname + window.location.search + (window.location.search ? '&' + "sdk=java" : '?' + "sdk=java");
	if (window.history && window.history.pushState) {
		window.history.pushState({path:newurl},'',newurl);
	}else{
		window.location.search = "sdk=java";
	}

	return "java";
}

function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) == variable) {
			return decodeURIComponent(pair[1]);
		}
	}
	return null;
}

// attach the controllers to this module
require('./appController')(starter);
require('./routes')(starter);

module.exports = starter;
