'use strict';

var controller = function ($state, app, logger, textBundle, accountService) {
	logger('trace controller');

	/* declaration */

	var vm = this;

	this.app = app;

	this.stopPropagation = stopPropagation;

	this.toggle = toggle;

	this.openConfiguration = openConfiguration;

	this.convertToString = convertToString;

	this.prettyPrint = prettyPrint;

	this.accountService = accountService;

	this.callTraces = [];

	this.getTraces = getTraces;

	this.convertJsonToTable = convertJsonToTable;

	this.addParams = addParams;

	this.curTab = 1;

	this.setTab = setTab;

	this.getTokenizationTypeDisplayName = getTokenizationTypeDisplayName;

	/* initialization */



	/* implementation */

	function getTokenizationTypeDisplayName(cardTokType) {
		return accountService.getTokenizationTypeDisplayName(cardTokType);
	}

	function convertToString(timestamp){
		var date = new Date(timestamp);
		return date.toString();
	}

	function convertToDateTimeString(timestamp){
		var date = new Date(timestamp);
		return date.toString();
	}

	function prettyPrint(obj){
		var str = JSON.stringify(JSON.parse(obj),null,4);
		return str;
	}

	function toggle(){
		if (!app.showTrace){
			getTraces();
		}
		app.showTrace = !app.showTrace;
	}

	function stopPropagation($event){
		$event.stopPropagation();
	}

	function openConfiguration(){
		getTraces();

		app.showTrace = false;

		app.showConfiguration = true;
	}

	function addParams(trc){
		for(var i in trc){
			if(trc[i].request){
				trc[i].requestParams=convertJsonToTable(trc[i].request,[],0);
				trc[i].showRequestPayload = false;
			}
			if(trc[i].response){
				trc[i].responseParams=convertJsonToTable(trc[i].response,[],0);
				trc[i].showResponsePayload = false;
			}
		}
		return trc;
	}

	function setTab(tab){
		vm.curTab = tab;
		for(var i in vm.callTraces){
			if(!accountService.masterpassUser && ['Tokenize', 'Suspend', 'Unsuspend', 'Delete', 'Transact', 'GetDigitalAssets', 'GetAsset', 'NotifyTokenUpdated'].indexOf(vm.callTraces[i].api) >= 0 && vm.curTab == 1){
				vm.callTraces[i].showTrace = true;
			}
			else if(accountService.masterpassUser && ['PaymentData', 'PreCheckoutData', 'ExpressCheckout', 'Postback', 'PairingId'].indexOf(vm.callTraces[i].api) >= 0 && vm.curTab == 1){
				vm.callTraces[i].showTrace = true;
			}
			else if (['SRCEnrollCard', 'SRCCheckout', 'SRCDigitalCardUpdateNotification', 'FidoEnrollFinancialInstrument', 'FidoTransactionAuthentication', 'AuthenticationLookup'].indexOf(vm.callTraces[i].api) >= 0 && vm.curTab == 2){
				vm.callTraces[i].showTrace = true;
			}
			else if(['AltIDCheckout'].indexOf(vm.callTraces[i].api) >= 0 && vm.curTab == 3){
				vm.callTraces[i].showTrace = true;
			}
			else if(['Authorise', 'Authorise3D', 'AuthoriseRecurring', 'DisableRecurring', 'Refund'].indexOf(vm.callTraces[i].api) >= 0 && vm.curTab == 4){
				vm.callTraces[i].showTrace = true;
			}
			else {
				vm.callTraces[i].showTrace = false;
			}
		}
	}

	function getTraces(){
		if(accountService.accountId != null){
			accountService.getTraces().then(
			function(trc){
				vm.callTraces = addParams(trc);
				setTab(vm.curTab);
			},
			function(data){
				//alert(data.message);
				$state.reload();
			});
		}
	}

	function convertJsonToTable(jsonObject, table, prefix)
	{
		if(typeof(jsonObject)!='object'){
			jsonObject = JSON.parse(jsonObject);
		}
		var i;
		var entity;
		var subEntity;
		var isArray=false;
		if (Array.isArray(jsonObject)){
			isArray=true;
		}
		for (var x in jsonObject) {
			if (jsonObject[x]) {
				entity = {};
				if (prefix == '') {
					entity.key = x;
				} else if (isArray) {
					entity.key = prefix+'['+x+']';
				} else {
					entity.key = prefix+'.'+x;
				}
				if (typeof(jsonObject[x]) == 'object') {
					table = convertJsonToTable(jsonObject[x], table, entity.key);
				} else {
					entity.value = jsonObject[x];
					table.push(entity);
				}
			}
		}
		return table;
	}

};

module.exports = function (module) {
	var name = 'TraceController';
	module.controller(name, controller);

	return name;
};
