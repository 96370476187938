'use strict';

var controller = function ($state, $window, $timeout, $rootScope, app, cart, channel, checkoutService, accountService, config, logger, textBundle) {
	logger('checkout controller');

	/* declaration */

	// Retrieve any existing checkout info

	var vm = this;
	this.config = config;

	// Regexes used to check user input
	this.isDigital = cart.cartContainer.digital;
	this.accountService = accountService;
	this.checkoutService = checkoutService;

	this.cart = cart;

	this.app = app;

	this.cards = [];

	this.handlePlaceOrderButton = handlePlaceOrderButton;

	this.placeOrder = placeOrder;

	this.years = [];

	this.states = config.states;

	this.order = checkoutService.order;

	this.getCards = getCards;

	this.animateScrollTop = animateScrollTop;

	this.setCard = setCard;

	this.selectedCard = checkoutService.getLastUsedCard() != null ? checkoutService.getLastUsedCard() : accountService.accountInfo.primaryPromoviehub;

	this.isFidoSupported = isFidoSupported;

	this.transactFido = transactFido;

	this.getTokenizationTypeDisplayName = getTokenizationTypeDisplayName;

	this.threeDSecure = false;

	this.show3DSecureForm = false;

	this.threeDSecureForm = {
		type: 'none',
		sendAccountInfo: true,
		accountAge: undefined,
		passwordChanged: undefined,
		paymentAccountAge: undefined,
		purchasesInLastSixMonths: undefined,
		sendMerchantRiskIndicator: true,
		mobilePhone: '',
		homePhone: '',
		reorderItem: false,
		sendPhoneNumbers: true,
	};

	this.dropdownValues = {
		accountAge: [
			{ value: "notApplicable" },
			{ value: "thisTransaction" },
			{ value: "lessThan30Days" },
			{ value: "from30To60Days" },
			{ value: "moreThan60Days" },
		],
		passwordChanged: [
			{ value: "notApplicable" },
			{ value: "thisTransaction" },
			{ value: "lessThan30Days" },
			{ value: "from30To60Days" },
			{ value: "moreThan60Days" },
		],
		paymentAccountAge: [
			{ value: "notApplicable" },
			{ value: "thisTransaction" },
			{ value: "lessThan30Days" },
			{ value: "from30To60Days" },
			{ value: "moreThan60Days" },
		],
		purchasesInLastSixMonths: [
			{ value: "0" },
			{ value: "1" },
			{ value: "2" },
			{ value: "5" },
			{ value: "10" },
		],
	}

	this.dtvc = false;

	this.submitForm = submitForm;

	this.getCardArt = checkoutService.getCardArt.bind(checkoutService);

	this.channelDetails = {};

	this.channel = channel;

	this.updateTax = updateTax;

	this.checkoutRedirectUrl = config.checkoutRedirectUrl;

	this.isMasterpassUser = false

	/* initialization */

	// If cart is empty, redirect to empty cart
	if((channel == null && cart.cartContainer['cartCount'] == 0) || accountService.masterpassUser){
		$state.go('cart');
	}

	checkoutService.order.saveCard = false;

	/* implementation */

	function submitForm(formName) {
		$rootScope.$broadcast('makeSubmit', {formName: formName})
	}

	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}

	function createAdyenForm(order, generationTime) {
		// The form element to encrypt.
		var form = document.getElementById('adyen-encrypted-form');
		var options = {};
		// Bind encryption options to the form.
		var encryptedBlobFieldName = "encryptedData";
		// The form element to encrypt.
		var form = document.getElementById('adyen-encrypted-form');
		var cardInfo;
		form.elements["generationTime"].value = generationTime;
		form.elements["holderName"].value = order.card.name;
		if(order.card.nonTokenized){
			form.elements["tokenNumber"].value = order.card.unmaskedNumber;
			form.elements["expiryMonth"].value = pad(order.card.month, 2);
			form.elements["expiryYear"].value = "20" + pad(order.card.year, 2);
		} else {
			form.elements["tokenNumber"].value = order.tokenCreditCard.cardNumber;
			form.elements["expiryMonth"].value = pad(order.tokenCreditCard.expMonth, 2);
			form.elements["expiryYear"].value = "20" + pad(order.tokenCreditCard.expYear, 2);
			if(order.tokenCreditCard != null && order.tokenCreditCard.cvc != null) {
				var input = document.createElement("input");
				input.setAttribute("type", "text");
				input.setAttribute("id", "cvc");
				input.setAttribute("data-encrypted-name", "cvc");
				form.appendChild(input);
				form.elements["cvc"].value = order.tokenCreditCard.cvc;
			}
		}

		options.name = encryptedBlobFieldName;
		options.onsubmit = function(e) {
			order.adyenEncryptedJSON = form.elements[encryptedBlobFieldName].value;
			authorizeCard(order);
			e.preventDefault();
		};
		adyen.createEncryptedForm(form, options);
		return form;
	}

	function checkForm(scope, form) {
		return scope[form].$valid;
	}

	function getCards(){
		accountService.getCards().then(
			function(cards){
				vm.cards = cards;
				if(cards.length == 0){
					$state.go('cart');
				}
			},
			function(){
				$state.reload();
			}
		);
	}

	function isFidoSupported() {
		accountService.isFidoSupported(vm.selectedCard).then(
			function (supported) {
				if (!supported) {
					alert("This card is not supported for MA-FIDO. Please select another card or 3DS option");
					document.getElementById("none").checked = true;
					document.getElementById("FIDO").disabled = true;
				} else {
					console.log("Selected card is MA-FIDO supported");
				}
			},
			function (data) {
				alert("Failed to lookup authentication methods for card");
			}
		);
	}


	function transactFido(card) {
		accountService.getFidoAuthenticationData(card).then(
			function (authReqData) {
				runTransact(card, authReqData);
			},
			function (data) {
				console.log("Failed to start MA-FIDO transaction:" + data);
				alert("Failed to start MA-FIDO transaction");
			}
		);
	}

	function getTokenizationTypeDisplayName(cardTokType) {
		return accountService.getTokenizationTypeDisplayName(cardTokType);
	}

	function runTransact(card, authReqData) {
		var authUri = authReqData.managedAuthUri ? authReqData.managedAuthUri : card.managedAuthUri;
		var payload = {
			"srcClientId": authReqData.srcClientId,
			"serviceId": authReqData.serviceId,
			"authenticationMethod": {
				"authenticationMethodType": "MANAGED_AUTHENTICATION",
				"authenticationSubject": "CARDHOLDER",
				"uriData": {
					"uri": authUri,
					"uriType": "WEB_URI"
				}
			},
			"accountReference": {
				"srcDigitalCardId": authReqData.srcDigitalCardId
			},
			"authenticationContext": {
				"authenticationReasons": [
					"TRANSACTION_AUTHENTICATION"
				],
				"dpaData": {
					"dpaName": authReqData.dpaName,
					"dpaPresentationName": authReqData.dpaPresentationName,
					"acquirerId": authReqData.acquirerId,
					"acquirerBin": authReqData.acquirerBin,
					"merchantCategoryCode": authReqData.merchantCategoryCode,
					"merchantCountryCode": authReqData.merchantCountryCode
				},
				"dpaTransactionOptions": {
					"transactionAmount": {
						"transactionAmount": Math.round(cart.cartContainer.totalPrice * 100) / 100,
						"transactionCurrencyCode": app.selectedCurrency.name
					},
					"merchantCategoryCode": authReqData.merchantCategoryCode,
					"merchantCountryCode": authReqData.merchantCountryCode,
					"threeDsPreference": "NONE"
				}
			}
		};

//		console.log('built payload' + JSON.stringify(payload));

		window.addEventListener('message', ({
			data,
			source,
			origin
		}) => {
			if (source !== popup) {
				console.log('Wrong Window');
				return // ensure it is the correct window
			}

			var trace = {
				"api": "FidoTransactionAuthentication",
				"tokenType": card.tokenType,
				"endpoint": authUri,
				"request": JSON.stringify({
					action: "Authenticate",
					payload
				}),
				"store": "promoviehub",
				"suffix": card.lastFour,
			};
			switch (data.action) {
				case "Ready":
					// Auth UI has loaded and is ready to receive the initial payload
//					console.log(payload);
					(async () => {
						popup.postMessage({
							action: "Authenticate",
							payload
						}, origin)
					})()
					break;
				case "Success":
					// Auth UI has completed with success
					window.removeEventListener('message', this);
					popup.close()
//					console.log(data.payload);
					if (data.payload.assuranceData) {
						saveFidoAssuranceData(card, data.payload.assuranceData, data.payload.srcCorrelationId);
					}
					trace.response = JSON.stringify(data);
					accountService.saveTrace(trace);
					// ok to place order now
					placeOrder(this, vm.order);
					break;
				case "Error":
					// Auth UI has failed to perform authentication
					window.removeEventListener('message', this);
					popup.close()
//					console.log(data.payload);
					trace.response = JSON.stringify(data);
					accountService.saveTrace(trace);
					break;
			}
		});

		const popup = window.open(authUri + '?origin=https%3A%2F%2F' + window.location.host, 'fidoenrollpopup', 'width=400,height=640');
	}

	function saveFidoAssuranceData(card, assuranceData, correlationId) {
//		console.log("assuranceData " + JSON.stringify(assuranceData));
		card.assuranceData = JSON.stringify(assuranceData);
		card.assuranceCorrelationId = correlationId;
		accountService.saveFidoAssuranceData(card).then(
			function () {
				console.log("MA-FIDO Trasact successfully completed");
			},
			function (data) {
				console.log("Failed to save MA-FIDO AssuranceData:" + data);
				alert("Failed to save MA-FIDO AssuranceData");
			}
		);
	}

	function updateTax(){
		if(vm.channel != null){
			channel = vm.channel;
			vm.channelDetails.tax = (channel.subscriptionFee + channel.monthlyFee) * 0.05;
			vm.channelDetails.totalPrice = channel.subscriptionFee + channel.monthlyFee + vm.channelDetails.tax;
		}
	}

	function animateScrollTop(){
		// $('html, body, .body').animate({
		// 	scrollTop: 0
		// }, 500);
	}

	function setCard(newCard){
		animateScrollTop();
		vm.showPrimary = true;
		vm.showCards = false;
		var cardCopy = angular.copy(newCard);
		newCard.id = vm.selectedCard.id;
		vm.selectedCard = newCard;
		$timeout(function(){
			vm.selectedCard.id = cardCopy.id;
		}, 500);
	}

	function collectBrowserInfo () {

		const screenWidth = window && window.screen ? window.screen.width : '';
		const screenHeight = window && window.screen ? window.screen.height : '';
		const colorDepth = window && window.screen ? window.screen.colorDepth : '';
		const userAgent = window && window.navigator ? window.navigator.userAgent : '';
		const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false;

		let language = '';
		if (window && window.navigator) {
			language = window.navigator.language
				? window.navigator.language
				: window.navigator.browserLanguage; // Else is for IE <+ 10
		}

		const d = new Date();
		const timeZoneOffset = d.getTimezoneOffset();

		const browserInfo = {
			screenWidth,
			screenHeight,
			colorDepth,
			userAgent,
			timeZoneOffset,
			language,
			javaEnabled,
		};

		return browserInfo;
	};

	function handlePlaceOrderButton(scope, order) {
		if (vm.threeDSecureForm.type == 'FIDO') {
			transactFido(vm.selectedCard);
		} else {
			placeOrder(scope, order);
		}
	}

	function placeOrder(scope, order) {
		var newOrder = angular.copy(order);
		if(vm.channel != null){
			newOrder.channel = vm.channel;
			newOrder.monthlyFee = vm.channel.monthlyFee;
			newOrder.subscriptionFee = vm.channel.subscriptionFee;
			newOrder.tax = vm.channelDetails.tax;
			newOrder.total = vm.channelDetails.totalPrice;
		}
		else {
			var products = cart.cartContainer.products;
			var newProducts = [];
			for(var product in products){
				products[product].productId = product;
				newProducts.push(products[product]);
			}
			newOrder.products = newProducts;
			newOrder.subtotal = cart.cartContainer.subtotal;
			newOrder.tax = cart.cartContainer.tax;
			newOrder.total = cart.cartContainer.totalPrice;
		}

		if(accountService.accountInfo.primaryPromoviehub == null){
			if(checkForm(scope, "noPrimary")){
				app.loading = true;
				newOrder.card.month = order.card.expiration.split('/')[0];
				newOrder.card.year = order.card.expiration.split('/')[1];
				newOrder.saveCard = order.saveCard;
				newOrder.card.name = order.card.address.first + ' ' + order.card.address.last;
				newOrder.card.type = accountService.detectCardType(order.card.cardNumber);
			}
			else {
				return;
			}
		}
		else {
			if(vm.showCardForm){
				if(checkForm(scope, "primaryCard")){
					app.loading = true;
					newOrder.saveCard = order.saveCard;
					newOrder.card.name = newOrder.address.first + ' ' + newOrder.address.last;
					newOrder.card.type = accountService.detectCardType(order.card.cardNumber);
				}
				else {
					return;
				}
			}
			else {
				app.loading = true;
				newOrder.card = vm.selectedCard;
				newOrder.saveCard = false;
			}
		}
		newOrder.currencyCode = app.selectedCurrency.name;
		newOrder.enable3DS = vm.threeDSecure;
		newOrder.useDTVC = vm.dtvc;
		newOrder.threeDSecureForm = vm.threeDSecureForm;
		newOrder.browserInfo = collectBrowserInfo();
		checkoutService.storeLastUsedCard(angular.copy(newOrder.card));
		checkoutService.placeOrder(newOrder).then(
			function(response){
				app.loading = true;
				vm.order = response.order;
				if(response.authorizeCard) {
					createAdyenForm(response.order, response.generationTime);
					$timeout(function () {
						document.getElementById("payButton").click();
					}, 0);
				}
				else {
					if (vm.channel == null){
						cart.clearCart();
					}
					checkoutService.initOrder();
					accountService.init(checkoutService);
					$state.go('confirm', {order: vm.order});
				}
			},
			function(data){
				app.loading = false;
				if(data != null){
					alert(data.message);
				}
				$state.reload();
			}
		)
	}

	function authorizeCard(order){
		app.loading = true;
		checkoutService.authorizeCard(order).then(
			function(response){
				var order = response.order;
				if(order.status == "RedirectShopper") {
					// redirect shopper to issuer site
					if(response.additionalData.issuerUrl != null) {
						var choice = confirm("Please click OK to continue the processing of your 3D Secure transaction.");
						if(choice) {
							checkoutService.storeCheckoutInfo(order);
							var form = document.getElementById('adyen-threeD-redirect');
							form.elements["PaReq"].value = response.additionalData.paRequest;
							form.elements["MD"].value = response.additionalData.md;
							form.elements["TermUrl"].value = config.checkout3DSAuthUrl + "?destUrl=" + config.checkoutRedirectUrl;
							form.action = response.additionalData.issuerUrl;
							$timeout(function () {
								document.getElementById("continueButton").click();
							}, 0);
						} else {
							app.loading = false;
							$state.reload();
						}
					} else {
						alert("Issuer url not provided for 3D Secure transaction");
						app.loading = false;
						$state.reload();
					}
				} else {
					if (vm.channel == null){
						cart.clearCart();
					}
					checkoutService.initOrder();
					accountService.init(checkoutService);
					$state.go('confirm', {order: order});
				}
			},
			function(data){
				if(data != null){
					alert(data.message);
				}
				app.loading = false;
				$state.reload();
			}
		)
	}
};

module.exports = function (module) {
	var name = 'CheckoutController';
	module.controller(name, controller);

	return name;
};
