'use strict';

var controller = function (app, logger, textBundle) {
	logger('loader controller');

	/* declaration */

	this.isLoading = isLoading;

	this.showConfiguration = showConfiguration;



	/* initialization */



	/* declaration */

	function isLoading(){
		return app.loading;
	}

	function showConfiguration(){
		return app.showConfiguration;
	}
};

module.exports = function (module) {
	var name = 'LoaderController';
	module.controller(name, controller);

	return name;
};