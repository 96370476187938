'use strict';
var angular = require('angular');
var configurationManagerService = angular.module('configurationManagerService', []);

var factory = function (app, config, configurationState, $http, localStorage, logger, $window) {

	var AUTO_RETRIEVE_PRECHECKOUT = true;

	var instance = {
		state : configurationState,

		availableConfigurations : {},

		page : 'menu',

		save : function(){
			this.state.save();
		},

		clear : function(){
			this.state.clear();
			this._validateState();
		},

		/**
		 * Retreives the environment configuration and loads the javascript environment.
		 * 
		 * @param environmentId - String environemnt to load
		 * @param callback - function executed when action completes. Passed the Environemnt configuration or null
		 *    if retireving the environment fails.
		 */
		loadEnvironment : function(environmentId, callback){
			var self = this;

			var requestParameters = { "environment" : environmentId };
			var requestUrl = config.serviceBase + config.masterpass.api.CONFIGURATION_ENVIRONMENT;
			$http.get(requestUrl, { "params" : requestParameters }).then(
				function(response){
					if(response.data && response.data.environment){

						// set environment
						self._setEnvironmentConfiguration(response.data.environment);
						// refresh checkout IDs
						self.getCheckoutIdentifiers(self.state.environment)

					}else{
						logger('unable to retrieve available endpoint environment - empty response: %s', environmentId);
					}
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					logger('unable to retrieve available endpoint environment: %s', environmentId);
					if(callback){
						callback(null);
					}
				}
			);
		},

		/**
		 * Retreivs the Shipping profiles for a given Checkout ID and stores them.
		 * 
		 * @param checkoutId - Object The Checkout ID object to get valid Shipping Profiles for.
		 * @param callback - function Called when action completes.  Passed the Shipping Profiles array 
		 *    or null if the call fails.
		 */
		getShippingProfiles : function(checkoutId, callback){
			var self = this;

			var requestUrl = config.serviceBase + config.masterpass.api.CONFIGURATION_SHIPPING_PROFILES
			var requestParameters = {"checkoutIdAlias" : checkoutId.alias}
			$http.get(requestUrl, {"params" : requestParameters}).then(
				function(response){
					self._setShippingProfiles(response.data.shippingProfiles);
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					logger('Unable to retrieve available Shipping Profiles');
					self._setShippingProfiles([]);
					if(callback){
						callback(null);
					}
				}
			);
		},

		/**
		 * Retrieves and sets the default configuration.
		 * 
		 * @param callback - function executed when action completes. Passed the configuration object or null
		 *    if the call fails.
		 */
		loadConfiguration : function(callback){
			var self = this;
			// load default configuration from server
			this.state.syncing = true;
			self.state.configurationLoaded = false;
			$http.get(config.serviceBase + config.masterpass.api.CONFIGURATION_DEFAULT_CONFIGURATION).then(
				function(response){
					// map configuration to local configuration object

					// endpoint configuration
					self._setEnvironmentConfiguration(response.data.environment);

					// MasterpassConfiguration
					self._setMasterpassConfiguration(response.data.masterpass);

					// checkout Identifier directly
					self.state.checkoutId = response.data.checkoutId;

					// checkout identifiers
					self.getCheckoutIdentifiers(self.state.environment);

					self.state.configurationLoaded = true;
					self.state.syncing = false;

					self.save();
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					self.state.syncing = false;
					logger("Unable to get default configuration.");
					$window.alert("Unable to retrieve default configuration.");
					if(callback){
						callback(null);
					}
				}
			);
		},

		/**
		 * Retrieves the available Environment configurations.
		 * 
		 * @param callback - function executed when action completes. Passed the environemnts array or null
		 *    if the call fails.
		 */
		getEnvironments : function(callback){
			var self = this;

			$http.get(config.serviceBase + config.masterpass.api.CONFIGURATION_ENVIRONMENTS).then(
				function(response){
					self.availableConfigurations.environments = response.data || [];
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					logger('unable to retrieve available environments');
					if(callback){
						callback(null);
					}
				}
			);
		},

		/**
		 * Retrieves the available checkout IDs for a given environment.  Will select a new Checkout ID if the 
		 *    current one is not in the new list.
		 * 
		 * @param environmentId - String
		 * @param callback - function executed when action completes. Passed the Checkout IDs array or null
		 *    if the call fails.
		 */
		getCheckoutIdentifiers : function(environmentId, callback){
			var self = this;

			var requestUrl = config.serviceBase + config.masterpass.api.CONFIGURATION_CHECKOUT_IDS;
			var requestParameters = {"environmentId": environmentId};
			$http.get(requestUrl, {"params": requestParameters}).then(
				function(response){
					// set available checkout IDs
					self.availableConfigurations.checkoutIdentifiers = response.data.checkoutIds || [];

					// select vaild checkout ID
					var defaultSelection = 0;
					if(self.state.checkoutId){
						for(var i = 0; i < self.availableConfigurations.checkoutIdentifiers.length; i++){
							if(self.state.checkoutId.alias == self.availableConfigurations.checkoutIdentifiers[i].alias){
								defaultSelection = i;
								break;
							}
						}
					}
					self._setCheckoutIdentifier(self.availableConfigurations.checkoutIdentifiers[defaultSelection]);

					if(callback){
						callback(response.data);
					}
				},
				function(response){
					logger('Configuration Manager: Unable to retrieve available Checkout Identifiers');
					if(callback){
						callback(null);
					}
				}
			);
		},

		/**
		 * Retrieves and sets the available loyalty programs.
		 * 
		 * @param callback - function executed when action completes. Passed the Loyalty program array or null
		 *    if the call fails.
		 */
		getLoyaltyPrograms : function(callback){
			var self = this;

			var requestUrl = config.serviceBase + config.masterpass.api.CONFIGURATION_LOYALTY_PROGRAMS
			$http.get(requestUrl).then(
				function(response){
					self._setLoyaltyPrograms(response.data.loyaltyPrograms);
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					logger('Unable to retrieve available Loyalty Programs');
					self._setLoyaltyPrograms([]);
					if(callback){
						callback(null);
					}
				}
			);
		},

		/**
		 * Retrieves and sets the default configuration.
		 * 
		 * @param callback - function executed when action completes. Passed the Private Lable Cards array
		 *    or null if the call fails.
		 */
		getPrivateLabelCards : function(callback){
			var self = this;

			var requestUrl = config.serviceBase + config.masterpass.api.CONFIGURATION_PRIVATE_LABEL_CARDS
			$http.get(requestUrl).then(
				function(response){
					self._setPrivateLabelCards(response.data.privateLabelCards);
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					logger('Unable to retrieve available Loyalty Programs');
					self._setPrivateLabelCards([]);
					if(callback){
						callback(null);
					}
				}
			);
		},

		/**
		 * Sets the Callback paths appropriately for the current window. Optionally 
		 *    allows providing additional parameters as a string to append the URL
		 * 
		 * @param optionalParameter - String to append as a parameter to URL
		 */
		setCallbackPaths : function(optionalParameter){
			var additionalParameter = '';
			if(optionalParameter){
				additionalParameter = '&' + optionalParameter;
			}
			this.state.callbackDomain =
					$window.location.protocol + "//" + $window.location.hostname + ($window.location.port ? (':' + $window.location.port) : '');
			this.state.checkoutCallbackPath =
					$window.location.pathname + $window.location.search + config.masterpass.callbackPath.CHECKOUT + additionalParameter;
			this.state.connectedCallbackPath =
					$window.location.pathname + $window.location.search + config.masterpass.callbackPath.CONNECTED + additionalParameter;
			this.state.pairingCallbackPath =
					$window.location.pathname + $window.location.search + config.masterpass.callbackPath.PAIR + additionalParameter;
			this.state.cardSecurityCallbackPath =
					$window.location.pathname + $window.location.search + config.masterpass.callbackPath.CARD_SECURITY + additionalParameter;
		},

		/**
		 * Retrieves and sets the default configuration.
		 * 
		 * @param callback - function executed when action completes
		 */
		reset : function(callback){
			this.clear();
			this.loadConfiguration(callback);
		},

		performCheckout : function(){
			this.setCallbackPaths('callbackReturn=true');
			masterpass.checkout(
				this.masterpassCheckoutCallback.bind(this),
				this.masterpassCheckoutCallback.bind(this),
				quickCart.cartContainer
			);
		},

		performConnect : function(){
			this.setCallbackPaths('callbackReturn=true');
			masterpass.connect(
				this.masterpassPairingCallback.bind(this),
				this.masterpassPairingCallback.bind(this),
				quickCart.cartContainer
			);
		},

		performPrecheckoutData : function(){
			var self = this
			self.setCallbackPaths('callbackReturn=true');
			masterpass.precheckoutData(
				function(data){
					self.navigateToCallbackPanel(data);
				},
				quickCart.cartContainer
			);
		},

		performExpressCheckout : function(){
			var self = this

			if(self.state.preCheckoutData){
				// perform express checkout
				self.setCallbackPaths('callbackReturn=true');
				masterpass.expressCheckout(
					self.masterpassCheckoutCallback.bind(self),
					self.masterpassCheckoutCallback.bind(self),
					quickCart.cartContainer
				);
			}else{
				if(AUTO_RETRIEVE_PRECHECKOUT){
					// perform preCheckout data
					self.setCallbackPaths('callbackReturn=true');
					masterpass.precheckoutData(
						function(data){
							if(data){
								self.performExpressCheckout()
							}else{
								self.navigateToCallbackPanel(data);
							}
						}
					);
				}else{
					self.performCheckout()
				}
			}
		},

		/**
		 * MasterPass callback handler for returning to the Configuration Screen's Callback pane.
		 * Calls updateReturnCheckoutButton() if already in Configuration Screens.
		 * 
		 */
		navigateToCallbackPanel : function(data){
			// check if button update will be called by configuration controller init
			var needsButtonUpdate = app.showConfiguration;

			app.loading = false;
			app.showTrace = false;
			app.showConfiguration = true;
			this.page = "callback";
			this.validateReturnData(data)
			if(needsButtonUpdate){
				this.updateReturnCheckoutButton(data)
			}
		},

		validateReturnData: function(data){
			if(data == null){
				$window.alert('Request Failed');
			}
		},

		/**
		 * MasterPass callback handler for pairing request made from Configuration Screen's Callback pane.
		 *    Sends you to Callback pane and requests precheckout data if pairing success
		 * 
		 */
		masterpassPairingCallback : function(data){
			var self = this

			// action was canceled
			if(data && data.mpstatus == config.masterpass.constants.STATUS.CANCEL){
				// re-open configuration
				if(!app.showConfiguration){
					app.loading = false;
					app.showTrace = false;
					app.showConfiguration = true;
				}
				return
			}

			// action failure
			if(!data || data.mpstatus == config.masterpass.constants.STATUS.FAILURE){
				self.navigateToCallbackPanel(data)
				return
			}

			// action success
			self.state.preCheckoutData = null
			if(self.state.longAccessToken && AUTO_RETRIEVE_PRECHECKOUT){
				masterpass.precheckoutData(function(data){
					self.navigateToCallbackPanel(data)
				});
			}else{
				self.navigateToCallbackPanel(data)
			}
		},

		/**
		 * MasterPass callback handler for returning to the Configuration Screen's Callback pane.
		 * 
		 */
		masterpassCheckoutCallback : function(data){
			var self = this

			// cancel
			if((data && data.mpstatus == config.masterpass.constants.STATUS.CANCEL)
					|| (masterpass.TREAT_FAILURE_AS_CANCEL && data && data.mpstatus == config.masterpass.constants.STATUS.FAILURE)){
				// re-open configuration
				if(!app.showConfiguration){
					app.loading = false;
					app.showTrace = false;
					app.showConfiguration = true;
				}
				return
			}

			// success
			if(data && !('mpstatus' in data)){

				// make a postback report if we got a checkout resource
				if(data.CheckoutResourceOutput || data.ExpressCheckoutResourceOutput){
					if(configurationState.checkout){
						masterpass.postback(quickCart.cartContainer)
					}
				}


				if(AUTO_RETRIEVE_PRECHECKOUT && self.state.longAccessToken && !self.state.preCheckoutData){
					masterpass.precheckoutData(function(data){
						self.navigateToCallbackPanel(data)
					});
					return
				}

				self.navigateToCallbackPanel(data)

			}else{
				// failure
				self.navigateToCallbackPanel(data)
			}

		},


		/**
		 * Load Lightbox Javascript into the page
		 */
		reloadJavascriptEnvironment : function(){
			var self = this;

			var currentScript = document.querySelector( '#javascriptEnvironment' );
			if(currentScript && currentScript.getAttribute('data-src') == this.state.lightboxUrl){
				// don't reload same script
				return;
			}else{
				if(currentScript){
					currentScript.parentNode.removeChild(currentScript);
				}
				self.state.lightboxUrlLoaded = false;

				// load new script
				var done = false;
				var scr = document.createElement('script');

				var handleLoad = function() {
					if (!done) {
						done = true;
						self.state.lightboxUrlLoaded = true;
					}
				}

				var handleReadyStateChange = function() {
					var state;

					if (!done) {
						state = scr.readyState;
						if (state === "complete") {
							handleLoad();
						}
					}
				}

				var handleError = function() {
					if (!done) {
						done = true;
						if(!$window.ignoreLoadError){
							$window.alert('Error loading Lightbox Javascript.  Check the Lightbox URL or reload the page.');
						}
						self.state.lightboxUrlLoaded = true;
					}
				}

				scr.id = 'javascriptEnvironment';
				scr.setAttribute('data-src', this.state.lightboxUrl);
				scr.onload = handleLoad;
				scr.onreadystatechange = handleReadyStateChange;
				scr.onerror = handleError;
				scr.src = this.state.lightboxUrl + (this.state.lightboxUrl.indexOf('?') > -1 ? '&' : '?') + 'ts=' + (new Date()).getTime();
				document.body.appendChild(scr);
			}
		},

		updateReturnCheckoutButton : function(ignoreShowConfigurationState){
			var container = document.querySelector('#config_mp_button');
			if(container){
				// clear old button
				container.innerHTML = '';
			}

			if(app.showConfiguration || ignoreShowConfigurationState){
				// load new button
				if(this.isConnectedCheckout()){
					this.setCallbackPaths('callbackReturn=true');
					masterpass.returnCheckout(
						'config_mp_button',
						this.masterpassCheckoutCallback.bind(this),
						this.masterpassCheckoutCallback.bind(this),
						quickCart.cartContainer
					);
				}
			}

		},

		isConnectedCheckout : function(){
			return Boolean(this.state.xmlVersion != 'v5'
					&& this.state.checkoutType == config.masterpass.constants.CHECKOUT_TYPE.CONNECT
					&& this.state.preCheckoutData
					&& this.state.longAccessToken)
		},

		/* private functions */

		_init : function(){
			// bind page navigation started event
			$window.addEventListener("beforeunload", function(){
				$window.ignoreLoadError = true
			}, false);

			// populate default objects
			this._validateState();

			this.setCallbackPaths();

			// get independent lists
			this.getEnvironments();
			this.getPrivateLabelCards();
			this.getLoyaltyPrograms();

			if(!this.state.configurationLoaded){
				// load configuration
				this.loadConfiguration();
			}else{
				// update lists
				this.getCheckoutIdentifiers(this.state.environment);

				// add JS library
				this.reloadJavascriptEnvironment();
			}
		},

		_setEnvironmentConfiguration : function(environment){
			this.state.environment = environment.id;
			this.state.consumerKey = environment.consumerKey;
			this.state.requestUrl = environment.requestTokenEndpoint;
			this.state.shoppingCartUrl = environment.shoppingCartEndpoint;
			this.state.merchantInitUrl = environment.merchantInitializationEndpoint;
			this.state.accessUrl = environment.accessTokenEndpoint;
			this.state.preCheckoutUrl = environment.precheckoutDataEndpoint;
			this.state.expressCheckoutUrl = environment.expressCheckoutEndpoint;
			this.state.postbackUrl = environment.postbackTransactionEndpoint;
			this.state.redirectUrl = environment.masterpassRedirectUrl;
			this.state.lightboxUrl = environment.lightboxJavascriptUrl;

			// reload new lightbox script
			this.reloadJavascriptEnvironment();
		},

		_setMasterpassConfiguration : function(masterpassConfig){
			//this.state.checkoutIdAlias = masterpassConfig.checkoutIdAlias;
			this.state.xmlVersion = masterpassConfig.masterpassVersion;
			this.state.authLevelBasic = masterpassConfig.advancedCheckoutOverride;
			this.state.checkoutType = masterpassConfig.checkoutType;
			this.state.pairingType = masterpassConfig.pairingType;
			this.state.showWalletSelection = masterpassConfig.showWalletSelection;
			this.state.pairingDataTypes = masterpassConfig.requestedDataTypes;
			this.state.acceptedCards = masterpassConfig.acceptedCardTypes;
			if(masterpassConfig.privateLabelCardTypes){
				this.state.privateLabelCardTypes = masterpassConfig.privateLabelCardTypes.split(',');
			}
			this.state.rewards = masterpassConfig.enableLoyaltyPrograms;
			if(masterpassConfig.allowedLoyaltyPrograms){
				this.state.allowedLoyaltyPrograms = masterpassConfig.allowedLoyaltyPrograms.split(',');
			}
			this.state.genericErrorMessage = masterpassConfig.genericErrorMessage;
			if(masterpassConfig.shippingLocationProfiles){
				this.state.shippingProfiles = masterpassConfig.shippingLocationProfiles.split(',');
			}

			this._validateState();
		},

		_setCheckoutIdentifier : function(checkoutIdentifier){
			this.state.checkoutId = checkoutIdentifier;

			this.getShippingProfiles(this.state.checkoutId);
		},

		_setShippingProfiles : function(profiles){
			this.availableConfigurations.shippingProfiles = profiles;

			// clear invalid selections
			var newShippingProfiles = [];
			if(this.state.shippingProfiles.length > 0){
				for(var i = 0; i < profiles.length; i++){
					if(this.state.shippingProfiles.indexOf(profiles[i].identifier) != -1){
						newShippingProfiles.push(profiles[i].identifier);
					}
				}
			}
			this.state.shippingProfiles = newShippingProfiles;
		},

		_setLoyaltyPrograms : function(loyaltyPrograms){
			this.availableConfigurations.loyaltyPrograms = loyaltyPrograms;
		},

		_setPrivateLabelCards : function(privateLabelCards){
			this.availableConfigurations.privateLabelCards = privateLabelCards;

			if(!this.availableConfigurations.privateLabelCards){
				this.availableConfigurations.privateLabelCards = [];
			}

			// clear invalid selections
			var newPrivateLabelCardTypes = [];
			if(this.state.privateLabelCardTypes.length > 0){
				for(var i = 0; i < privateLabelCards.length; i++){
					if(this.state.privateLabelCardTypes.indexOf(privateLabelCards[i].identifier) != -1){
						newPrivateLabelCardTypes.push(privateLabelCards[i].identifier);
					}
				}
			}
			this.state.privateLabelCardTypes = newPrivateLabelCardTypes;
			this._validateState();
		},

		_validateState : function(){
			// static defined configuration properties
			configurationState.forceCallbackUrl = false
			this.state.rewards = Boolean(this.state.rewards);
			this.state.realm = config.masterpass.constants.REALM;
			if(!this.state.currencyCode){
				this.state.currencyCode = config.constants.CURRENCY[0];
			}
			// ensure properties are arrays
			this.state.privateLabelCardTypes = this.state.privateLabelCardTypes || [];
			this.state.allowedLoyaltyPrograms = this.state.allowedLoyaltyPrograms || [];
			this.state.shippingProfiles = this.state.shippingProfiles || [];
			this.state.acceptedCards = this.state.acceptedCards || [];
			if(this.state.acceptedCards.length == 0 && this.state.privateLabelCardTypes.length == 0){
				this.state.acceptedCards.push(config.masterpass.constants.CREDIT_CARDS[0]);
			}
		}
	};

	//instance._init();

	return instance;
};

configurationManagerService.factory('configurationManager', ['app', 'config', 'configurationState', '$http', 'localStorageService', 'logger', '$window', factory]);
module.exports = configurationManagerService.name;
