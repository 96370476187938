'use strict';
var angular = require('angular');
var fpanCheckoutServiceProvider = angular.module('fpanCheckoutServiceProvider', []);


var factory = function ($state, $window, $q, $http, accountService, app, cart, config, configurationManager, localStorageService) {

	var LOCALSTORAGE_KEY = 'fpanCheckoutInformation';
	var url = config.authBase + "/fpanCheckout";
	var checkoutInfo = null;

	return {

		order: {
			"card": {}
		},

		initOrder: function() {
			this.order = {};
			this.order.card = {};
		},

		initializeReview : function(){
			var self = this;
		},

		placeOrder: function(order) {
			return $http({
				method: 'POST',
				url: url + '/placeOrder',
				withCredentials: true,
				data: order,
				params: {
					store: config.storeName,
				}
			})
			.then(
				function(response){
					return response.data;
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		authorizeCard: function(order) {
			return $http({
				method: 'POST',
				url: url + '/authorizeCard',
				withCredentials: true,
				data: order
			})
			.then(
				function(response){
					return (response.data);
				},
				function(response){
					return $q.reject(response.data);
				}
			)
		},

		storeCheckoutInfo : function(checkoutInfo){
			localStorageService.set(LOCALSTORAGE_KEY, checkoutInfo);
		},

		getCheckoutInfo : function(){
			return localStorageService.get(LOCALSTORAGE_KEY);
		},

		clearCheckoutInfo : function(){
			localStorageService.remove(LOCALSTORAGE_KEY);
		},

		generateCardBrandingImage : function(cardType){
			if(cardType == 'jccc'){
				return 'images/cards/' + cardType + '.png';
			}
			// uses card type to create image URL, defaulting private-label cards to default image
			if(cardType && config.masterpass.constants.CREDIT_CARDS.indexOf(cardType) != -1){
				return 'images/cards/' + cardType + '.png';
			}else{
				return 'images/cards/card.png';
			}
		},

		getCardArt : function(card){
			if(card.status){
				return accountService.getCardArtUrl(card.id);
			}else{
				return this.generateCardBrandingImage(card.type);
			}
		}

	}
};

fpanCheckoutServiceProvider.factory('fpanCheckoutService', ['$state', '$window', '$q', '$http', 'accountService', 'app', 'cart', 'config', 'configurationManager', 'localStorageService', factory]);
module.exports = fpanCheckoutServiceProvider.name;
