'use strict';

var controller = function ($state, $window, $location, app, logger, textBundle) {
	logger('masterpass checkout callback controller');

	/* declaration */

	var vm = this;
		
	this.redirectToCheckout = redirectToCheckout;

	this.checkoutUrl = "#/masterpassCheckout";

	/* initialization */

	app.loading = true;

	redirectToCheckout();

	/* implementation */

	function redirectToCheckout() {

		var params  = window.location.search;
		console.log($location.absUrl());
		var url = $location.absUrl().split("?");
		window.location.replace(url[0]+ vm.checkoutUrl +params);

	}
};

module.exports = function (module) {
	var name = 'masterpassCallbackController';
	module.controller(name, controller);

	return name;
};