'use strict';
var angular = require('angular');
var configurationManagerService = angular.module('configurationStateService', []);

var factory = function (localStorage, logger) {
	var LOCALSTORAGE_KEY = 'configurationData';

	var configurationState = localStorage.get(LOCALSTORAGE_KEY) || {};


	Object.defineProperty(configurationState, 'save', {
			enumerable: false,
			configurable: false,
			writable: false,
			value: function(){
				localStorage.set(LOCALSTORAGE_KEY, this);
			}
		}
	)

	Object.defineProperty(configurationState, 'clear', {
			enumerable: false,
			configurable: false,
			writable: false,
			value: function(){
				for(var prop in this){
					delete this[prop];
				}
				this.save();
			}
		}
	)


	return configurationState;
};

configurationManagerService.factory('configurationState', ['localStorageService', 'logger', factory]);
module.exports = configurationManagerService.name;
