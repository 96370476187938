'use strict';

var controller = function ($state, $window, $timeout, $rootScope, app, cart, accountService, altIdCheckoutService, config, logger, textBundle, altIdCheckoutResp) {
	logger('alt id review controller');

  var vm = this;

	this.config = config;

	this.accountService = accountService;

	this.info = altIdCheckoutService.getCheckoutInfo(); // Retrieve previously entered user info from local storage

	this.app = app;

	this.cart = cart;

	this.isDigital = cart.cartContainer.digital;

	this.authorizeCard = authorizeCard;

	this.generateCardBrandingImage = altIdCheckoutService.generateCardBrandingImage;

	this.isMixed = cart.checkMixed();

	this.altIdCheckoutResp = altIdCheckoutResp;

	if(this.info.id) {
		accountService.getOrder(this.info.id).then(
			function(currOrder){
				if(currOrder && currOrder.status == "Authorised"){
					cart.clearCart();
					altIdCheckoutService.clearCheckoutInfo();
					altIdCheckoutService.initOrder();
					accountService.init(altIdCheckoutService);
					$state.go('confirm', {order: currOrder});
					return;
				} else {
					$window.scrollTo(0,0);
					altIdCheckoutService.initializeReview();
				}
			},
			function(data){
				if(data != null){
					alert(data.message);
				}
				app.loading = false;
				$window.scrollTo(0,0);
				altIdCheckoutService.initializeReview();
			}
		);
	}

	// If cart is empty, redirect to empty cart
	if(Object.keys(this.info).length <= 2){
		$state.go('cart');
		return;
	}

	/* implementation */

	function createAdyenForm(order) {
		// The form element to encrypt.
		var form = document.getElementById('adyen-encrypted-form');
		var options = {};
		// Bind encryption options to the form.
		var encryptedBlobFieldName = "encryptedData";
		form.elements["generationTime"].value = (new Date()).toISOString();
		form.elements["holderName"].value = order.card.name;
		form.elements["tokenNumber"].value = order.tokenCreditCard.cardNumber;
		form.elements["expiryMonth"].value = pad(order.card.month, 2);
		form.elements["expiryYear"].value = pad(order.card.year, 4);
		if(order.card.cvv != null) {
			var input = document.createElement("input");
			input.setAttribute("type", "text");
			input.setAttribute("id", "cvc");
			input.setAttribute("data-encrypted-name", "cvc");
			form.appendChild(input);
			// TODO: Need to figure out how to pass altIdCheckoutResp.cryptogram.de48se43Data
			// for now pass the cvv so that adyen does not complain.
			form.elements["cvc"].value = order.card.cvv;
		}

		options.name = encryptedBlobFieldName;
		options.onsubmit = function(e) {
			order.adyenEncryptedJSON = form.elements[encryptedBlobFieldName].value;
			e.preventDefault();
		};
		adyen.createEncryptedForm(form, options);
		return form;
	}

	function collectBrowserInfo () {

		const screenWidth = window && window.screen ? window.screen.width : '';
		const screenHeight = window && window.screen ? window.screen.height : '';
		const colorDepth = window && window.screen ? window.screen.colorDepth : '';
		const userAgent = window && window.navigator ? window.navigator.userAgent : '';
		const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false;

		let language = '';
		if (window && window.navigator) {
			language = window.navigator.language
				? window.navigator.language
				: window.navigator.browserLanguage; // Else is for IE <+ 10
		}

		const d = new Date();
		const timeZoneOffset = d.getTimezoneOffset();

		const browserInfo = {
			screenWidth,
			screenHeight,
			colorDepth,
			userAgent,
			timeZoneOffset,
			language,
			javaEnabled,
		};

		return browserInfo;
	};

	function authorizeCard(){
		var newOrder = angular.copy(altIdCheckoutService.order);
		var products = cart.cartContainer.products;
		var newProducts = [];
		for(var product in products){
			products[product].productId = product;
			newProducts.push(products[product]);
		}
		newOrder.products = newProducts;
		newOrder.store = "promoviehub";
		newOrder.subtotal = cart.cartContainer.subtotal;
		newOrder.tax = cart.cartContainer.tax;
		newOrder.total = cart.cartContainer.totalPrice;

		newOrder.tokenCreditCard = {};
		newOrder.tokenCreditCard.cardNumber = this.altIdCheckoutResp.cryptogram.accountNumber;
		newOrder.tokenCreditCard.eci = '02';
		newOrder.tokenCreditCard.ucafData = this.altIdCheckoutResp.cryptogram.de48se43Data;
		newOrder.tokenCreditCard.expMonth = this.info.expMonth;
		newOrder.tokenCreditCard.expYear = this.info.expYear;

		newOrder.card.cardNumber = this.info._creditCardNumber;
		newOrder.card.cvv = this.info._cvv;
		newOrder.card.month = this.info.expMonth;
		newOrder.card.year = this.info.expYear;
		newOrder.card.name = this.info.fullName;
		newOrder.card.type = this.info.cardType;
		newOrder.card.nonTokenized = false;

		newOrder.currencyCode = app.selectedCurrency.name;

		newOrder.enable3DS = this.info.threeDSecure;
		newOrder.threeDSecureForm = {
			type: '3DSecure2',
			sendAccountInfo: true,
			accountAge: undefined,
			passwordChanged: undefined,
			paymentAccountAge: undefined,
			purchasesInLastSixMonths: undefined,
			sendMerchantRiskIndicator: true,
			mobilePhone: '',
			homePhone: '',
			reorderItem: false,
			sendPhoneNumbers: false,
		};

		app.loading = true;
		createAdyenForm(newOrder);
		$timeout(function () {
			document.getElementById("payButton").click();
		}, 0);

		$timeout(function () {
			// don't send raw pan details to the server
			newOrder.card.cardNumber = '************' + newOrder.card.cardNumber.slice(newOrder.card.cardNumber.length - 4);
			newOrder.card.cvv = '***';
			newOrder.browserInfo = collectBrowserInfo();
			altIdCheckoutService.authorizeCard(newOrder).then(
				function(response){
					app.loading = false;
					vm.order = response.order;
					if(vm.order.status == "RedirectShopper") {
						// redirect shopper to issuer site
						if(response.additionalData.issuerUrl != null) {
							var choice = confirm("Please click OK to continue the processing of your 3D Secure transaction.");
							if(choice) {
								altIdCheckoutService.storeCheckoutInfo(vm.order);
								var form = document.getElementById('adyen-threeD-redirect');
								form["PaReq"].value = response.additionalData.paRequest;
								form["MD"].value = response.additionalData.md;
								form["TermUrl"].value = config.altId3DSAuthUrl + "?orderId=" + vm.order.id + "&destUrl=" + window.location.href;
								form.action = response.additionalData.issuerUrl;
								$timeout(function () {
									document.getElementById("continueButton").click();
								}, 0);
							} else {
								app.loading = false;
								$state.reload();
							}
						} else {
							alert("Issuer url not provided for 3D Secure transaction");
							app.loading = false;
							$state.reload();
						}
					} else {
						cart.clearCart();
						altIdCheckoutService.clearCheckoutInfo();
						altIdCheckoutService.initOrder();
						accountService.init(altIdCheckoutService);
						$state.go('confirm', {order: vm.order});
					}
				},
				function(data){
					app.loading = false;
					if(data != null){
						alert(data.message);
					}
					$state.reload();
				}
			);
		}, 100);
	}

	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}
};

module.exports = function (module) {
	var name = 'AltIdReviewController';
	module.controller(name, controller);

	return name;
};
