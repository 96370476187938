'use strict';

var controller = function ($state, $window, $timeout, $rootScope, app, cart, md, paRes, checkoutService, accountService, logger, textBundle) {
	logger('checkout redirect controller');

	/* declaration */

	var vm = this;

	this.accountService = accountService;
	
	this.checkoutService = checkoutService;

	this.cart = cart;
	
	this.md = md;

	this.paRes = paRes;

	this.completeAuthorization = completeAuthorization;

	/* initialization */

	app.loading = true;

	completeAuthorization();

	/* implementation */

	function completeAuthorization(){

		var orderInfo = checkoutService.getCheckoutInfo();
		
		// If checkoutInfo is empty, alert user and go to cart
		
		if(orderInfo != null) {
			orderInfo.md = vm.md;
			orderInfo.paRes = vm.paRes;
			checkoutService.clearCheckoutInfo();
			checkoutService.completeAuthorization(orderInfo).then(
				function(response){
					var order = response.order;					
					if (order.channel == null){
						cart.clearCart();
					}
					checkoutService.initOrder();
					accountService.init(checkoutService);
					$state.go('confirm', {order: order});		
				},
				function(data){
					if(data != null){
						alert(data.message);
					}
					app.loading = false;
					if(orderInfo.channel != null){
						$state.go('checkout',{channelId: orderInfo.channel.channelId});
					} else {
						$state.go('checkout');
					}				
				}
			)
		} else {
			alert("Sorry, we encountered an error while processing your order!");
			app.loading = false;
			$state.go('checkout');			
		}
	}
};

module.exports = function (module) {
	var name = 'checkoutRedirectController';
	module.controller(name, controller);

	return name;
};