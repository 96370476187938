'use strict';
var angular = require('angular');
var accountService = angular.module('accountServiceProvider', []);

var factory = function (config, localStorage, $q, $http, $rootScope, app) {
	var LOCALSTORAGE_KEY = "accountInfo";

	var accountInfo = localStorage.get(LOCALSTORAGE_KEY);
	if(!accountInfo){
		accountInfo = {"currentUser": null, "accounts": config.ACCOUNTS};
		localStorage.set(LOCALSTORAGE_KEY, accountInfo);
	}

	var url = config.authBase + "/account";
	var storeName = config.storeName;
	var adminUsers = config.adminUsers;
	var instance = {
		/**
		 * Login function returns true or false if logged in
		 */
		accountId: null,
		showCards: true,
		showPrimary: true,
		editCardForm: false,
		addCardForm: false,
		expandCards: false,
		editInfo: false,
		pwChange: false,
		accountInfo: {},
		pageList: [],
		orders: [],
		cards: [],
		traces: [],
		masterpassUser: false,
		login: function(username, password) {
			var fd = new FormData();
			fd.append('username', username);
			fd.append('password', password);
			return $http.post(url + '/authenticate', fd, {
					headers: {
						'Content-Type': undefined
					},
					transformRequest: angular.identity,
					params: fd,
					withCredentials: true
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);

		},
		logout: function() {
			return $http({
					method: 'POST',
					url: url + '/logout',
					withCredentials: true
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		getAccountId: function() {
			return $http({
					method: 'GET',
					url: url + '/accountId',
					withCredentials: true
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		getAccountInfo: function() {
			return $http({
					method: 'GET',
					url: url + '/accountInfo',
					withCredentials: true
				})
				.then(
					function(response){
						instance.masterpassUser = instance._isMasterpassUser(response.data.userType);
						return response.data;
					},
					function(errorResponse){
						instance.masterpassUser = false;
						return $q.reject(errorResponse.data);
					}
				);
		},
		getTraces: function() {
			return $http({
					method: 'GET',
					url: url + '/getTraces',
					withCredentials: true,
					params: {
						store: storeName,
					}
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		getOrders: function(page, size, sort) {
			return $http({
					method: 'GET',
					url: url + '/getOrders',
					withCredentials: true,
					params: {
						store: storeName,
						page: page,
						size: size,
						sort: sort
					}
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		getOrder: function(orderId) {
			return $http({
					method: 'GET',
					url: url + '/getOrder',
					withCredentials: true,
					params: {
						orderId: orderId
					}
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		getCardArtUrl: function(cardId) {
			if(typeof cardId === 'object'){
				cardId = cardId.id
			}
			return url + '/getCardArt?store=' + storeName + '&cardId=' + cardId;
		},
		getCards: function() {
			return $http({
					method: 'GET',
					url: url + '/getCards',
					withCredentials: true,
					params: {
						store: storeName,
					}
					})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		saveCard: function(card) {
			return $http({
					method: 'POST',
					url: url + '/saveCard',
					withCredentials: true,
					data: card
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		unsuspendCard: function(card) {
			return $http({
					method: 'POST',
					url: url + '/unsuspendCard',
					withCredentials: true,
					data: card,
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		suspendCard: function(card) {
			return $http({
					method: 'POST',
					url: url + '/suspendCard',
					withCredentials: true,
					data: card,
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		removeCard: function(card) {
			return $http({
					method: 'POST',
					url: url + '/removeCard',
					withCredentials: true,
					data: card,
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		getFidoAuthenticationData: function (card) {
			return $http({
					method: 'POST',
				url: url + '/getFidoAuthenticationData',
					withCredentials: true,
					data: card,
				})
				.then(
					function (response) {
						return response.data;
					},
					function (errorResponse) {
						return $q.reject(errorResponse.data);
					}
				);
		},
		saveFidoAssuranceData: function (card) {
			return $http({
				method: 'POST',
				url: url + '/saveFidoAssuranceData',
				withCredentials: true,
				data: card,
			})
				.then(
					function (response) {
						return response.data;
					},
					function (errorResponse) {
						return $q.reject(errorResponse.data);
					}
				);
		},
		isFidoSupported: function (card) {
			return $http({
				method: 'POST',
				url: url + '/isFidoSupported',
				withCredentials: true,
				data: card,
			})
				.then(
					function (response) {
						return response.data;
					},
					function (errorResponse) {
						return $q.reject(errorResponse.data);
					}
				);
		},
		getCustomSrcTokenizationTypes: function () {
			return $http({
				method: 'GET',
				url: url + '/getCustomSrcTokenizationTypes',
				withCredentials: true,
			})
				.then(
					function (response) {
						return response.data;
					},
					function (errorResponse) {
						return $q.reject(errorResponse.data);
					}
				);
		},
		saveCustomSrcTokenizationTypes: function (customTokenTypes) {
			return $http({
				method: 'POST',
				url: url + '/saveCustomSrcTokenizationTypes',
				withCredentials: true,
				data: customTokenTypes,
			})
				.then(
					function (response) {
						return response.data;
					},
					function (errorResponse) {
						return $q.reject(errorResponse.data);
					}
				);
		},
		getTokenizationTypeDisplayName: function (cardTokType) {
			if (cardTokType === 'src') {
				return 'SRC4M';
			}
			var srcInd = cardTokType.indexOf('SRC_');
			if (srcInd == 0) {
				return cardTokType.substring(4);
			}
			return cardTokType;
		},
		saveTrace: function (trace) {
			return $http({
				method: 'POST',
				url: url + '/saveTrace',
				withCredentials: true,
				data: trace,
			})
				.then(
					function (response) {
						return response.data;
					},
					function (errorResponse) {
						return $q.reject(errorResponse.data);
					}
				);
		},
		changePrimary: function(card) {
			return $http({
					method: 'POST',
					url: url + '/changePrimary',
					withCredentials: true,
					data: card,
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		createAccount: function(user) {
			return $http({
					method: 'POST',
					url: url + '/createAccount',
					withCredentials: true,
					data: user,
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		updateAccount: function(user) {
			return $http({
					method: 'POST',
					url: url + '/updateAccount',
					withCredentials: true,
					data: user,
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		updateLastLogIn: function() {
			return $http({
					method: 'POST',
					url: url + '/updateLastLogIn',
					withCredentials: true
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		changePassword: function(passwords) {
			return $http({
					method: 'POST',
					url: url + '/changePassword',
					withCredentials: true,
					data: passwords,
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},
		getName : function(){
			if(this.isLoggedIn()){
				return this._getCurrentUser().name;
			}
		},
		getEmail : function(){
			if(this.isLoggedIn()){
				return this._getCurrentUser().email;
			}
		},
		authorizeUser : function(password){
			return $http({
					method: 'POST',
					url: url + '/authorizeUser',
					withCredentials: true,
					data: password,
				})
				.then(
					function(response){
						$rootScope.authorized = response.data;
						return $rootScope.authorized;
					},
					function(errorResponse){
						$rootScope.authorized = false;
						return $q.reject();
					}
				);
		},
		getPairingId : function(){
			return $http({
					method: 'GET',
					url: url + '/getPairingId',
					withCredentials: true,
					params: {
						store: config.storeName,
					}
				})
				.then(
					function(response){
						return response.data;
					},
					function(response){
						return $q.reject(response.data);
					}
				);
		},
		isAdminUser: function () {
			if (adminUsers && adminUsers.includes(this.accountId)) {
				return true;
			}
			return false;
		},
		init : function(checkoutService){
			instance.authorizeUser();
			instance.getAccountId().then(
				function(accountId) {
					instance.accountId = accountId.user;
					instance.getAccountInfo().then(
						function(accountInfo) {
							instance.accountInfo = accountInfo;
						},
						function() {
							instance.accountId = null;
							instance.accountInfo = {};
							checkoutService.order = checkoutService.order || {};
							checkoutService.order.card = {};
						}
					);
				},
				function() {
					instance.accountId = null;
					instance.accountInfo = {};
					checkoutService.order = checkoutService.order || {};
					checkoutService.order.card = {};
				}
			);
		},

		detectCardType : function(number){
			return "master";
		/*	// maestro
			if (/^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/.test(number)){
				return "maestro";
			}

			// mastercard
			if (/^5[1-5][0-9]{5,}$/.test(number)){
				return "master";
			}

			// visa
			if (/^4[0-9]{6,}$/.test(number)){
				return "visa";
			}

			// amex
			if (/^3[47][0-9]{5,}$/.test(number)){
				return "amex";
			}

			// diners
			if (/^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/.test(number)){
				return "diners";
			}

			// discover
			if (/^6(?:011|5[0-9]{2})[0-9]{3,}$/.test(number)){
				return "discover";
			}

			// unknown
			return null;*/
		},

		/* Internal properties and methods */

		_data : accountInfo,

		_getCurrentUser : function(){
			return this._data.accounts[this._data.currentUser];
		},
		_setCurrentUser : function(username){
			this._data.currentUser = username;
			this._saveData();
		},

		_userExists : function(username){
			if(this._data.accounts[username]){
				return true;
			}else{
				return false;
			}
		},

		_isMasterpassUser : function(userType){
			return (userType === 'masterpass');
		},

		_saveData : function(){
			localStorage.set(LOCALSTORAGE_KEY, this._data);
		}

	};

	instance.init({});

	return instance;
};

accountService.factory('accountService', ['config', 'localStorageService', '$q', '$http', '$rootScope', factory]);
module.exports = accountService.name;
