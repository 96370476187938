'use strict';
var angular = require('angular');
var categoryService = angular.module('categoryService', []);

var factory = function (app, $http) {
	return {

		categoryPath: [],
		categoryMap: {},
		currentCategory : '',
		categoryList : {}

	};
};

categoryService.factory('categoryService', ['app', '$http', factory]);
module.exports = categoryService.name;