'use strict';

var controller = function ($scope, $state, $stateParams, $window, app, accountService, channelList, logger, textBundle) {
	logger('channelListing controller');

	/* declaration */
	this.accountService = accountService;
	this.channelList = channelList;
	this.stateParams = $stateParams;
	this.state = $state;
	this.app = app;

	/* initialization */

	// Redirect to home page if masterpass user
	if(accountService.masterpassUser){
		$state.go('home');
	}

};


module.exports = function (module) {
	var name = 'channelListingController';
	module.controller(name, controller);

	return name;
};