'use strict';

var controller = function (accountService, $state, $scope, $stateParams, $rootScope, $location, $timeout, $window, app, config, logger, textBundle) {
	/* declaration */

	var vm = this;

	this.app = app;

	this.login = login;

	this.isAuthorized = false;

	this.user = {};

	this.authorized = false;

	this.errorMessage = "";

	this.showError = false;

	this.state = $state;

	this.setTab = setTab;

	this.accountService = accountService;

	this.TandC = config.TandC;

	/* initialization */

	$rootScope.$on('$stateChangeSuccess', function(event, to, toParams, from, fromParams) {
		$rootScope.previousState = fromParams.tab;
		vm.previousState = from.name;
		vm.nextState = to.name;
	});

	/* implementation */

	function setTab(tab){
		$location.search({tab: tab});
	}

	function login(scope, user) {
		if(checkForm(scope, "authorizationForm")){
			accountService.authorizeUser(user.password).then(
					function(isAuthorized){
						vm.errorMessage = "";
					},
					function(){
						vm.errorMessage = "Invalid Password";
					}
				);
		}
	}

	function checkForm(scope, form) {
		return scope[form].$valid;
	}


};

module.exports = function (module) {
	var name = 'websiteLoginController';
	module.controller(name, controller);

	return name;
};