'use strict';

var controller = function ($state, $window, $location, $timeout, $rootScope, app, cart, channel, pairingId, checkoutService, accountService, localStorageService, config, logger, textBundle) {
	logger('masterpass checkout controller');

	/* declaration */

	// Retrieve any existing checkout info

	var vm = this;
	this.config = config;

	// Regexes used to check user input
	this.isDigital = cart.cartContainer.digital;

	this.accountService = accountService;

	this.checkoutService = checkoutService;

	this.cart = cart;

	this.app = app;

	this.cards = [];

	this.placeOrder = placeOrder;

	this.years = [];

	this.states = config.states;

	this.order = checkoutService.order;

	this.animateScrollTop = animateScrollTop;

	this.setCard = setCard;

	this.selectedCard = null;

	this.selectedAddress = null;

	this.ThreeDSecure = false;

	this.threeDSecureForm = {
		type: 'none',
		sendAccountInfo: true,
		accountAge: undefined,
		passwordChanged: undefined,
		paymentAccountAge: undefined,
		purchasesInLastSixMonths: undefined,
		sendMerchantRiskIndicator: true,
		mobilePhone: '',
		homePhone: '',
		reorderItem: false,
		sendPhoneNumbers: true,
	};

	this.dropdownValues = {
		accountAge: [
			{ value: "notApplicable" },
			{ value: "thisTransaction" },
			{ value: "lessThan30Days" },
			{ value: "from30To60Days" },
			{ value: "moreThan60Days" },
		],
		passwordChanged: [
			{ value: "notApplicable" },
			{ value: "thisTransaction" },
			{ value: "lessThan30Days" },
			{ value: "from30To60Days" },
			{ value: "moreThan60Days" },
		],
		paymentAccountAge: [
			{ value: "notApplicable" },
			{ value: "thisTransaction" },
			{ value: "lessThan30Days" },
			{ value: "from30To60Days" },
			{ value: "moreThan60Days" },
		],
		purchasesInLastSixMonths: [
			{ value: "0" },
			{ value: "1" },
			{ value: "2" },
			{ value: "5" },
			{ value: "10" },
		],
	}
	this.submitForm = submitForm;

	this.getCardArt = checkoutService.getCardArt.bind(checkoutService);

	this.channelDetails = {};

	this.channel = channel;

	this.updateTax = updateTax;

	this.checkoutRedirectUrl = config.checkoutRedirectUrl;

	this.masterpassData = {};

	this.paymentData = null;

	this.isExpressCheckout = isExpressCheckout;

	this.generationTime = "";

	this.authorizeCard = true;

	this.pairingId = pairingId;

	this.preCheckoutData = null;

	this.getMasterpassData = getMasterpassData;

	this.getPreCheckoutData = getPreCheckoutData;

	this.createAdyenForm = createAdyenForm;

	this.setAddress = setAddress;

	this.expressCheckout = expressCheckout;

	this.handleAuthorization = handleAuthorization;

	this.authorizeCard = authorizeCard;

	this.confirmCheckout = confirmCheckout;

	/* initialization */

	// If cart is empty, redirect to empty cart
	if(cart.cartContainer['cartCount'] == 0){
		$state.go('cart');
	}

	$timeout(function() {
		if(!accountService.masterpassUser) {
			logger('Not a masterpass user. sending to cart');
			$state.go('cart');
		}
	}, 2000);

	checkoutService.order.saveCard = false;

	vm.isExpressCheckout();


	/* implementation */

	function isExpressCheckout(){
		if(vm.pairingId != null){
			getPreCheckoutData();
		} else {
			validateUrl();
		}
	}

	function validateUrl(){
		var paramObject = $location.search();
		if (paramObject.mpstatus == null){
			var params  = window.location.search;
			var url = $location.absUrl().split("?");
			window.location.replace(url[0]+ "#/masterpassCheckout" +params);
		} else {
			getMasterpassData();
		}
	}

	function getMasterpassData() {
		app.loading = true;

		var paramObject = $location.search();
		//var paramObject = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });

		vm.masterpassData.cartId = localStorageService.get('cartId');

   		if (paramObject.mpstatus == 'success') {
   			vm.masterpassData.cartId = localStorageService.get('cartId');
   			vm.masterpassData.oauthVerifier = paramObject.oauth_verifier;
   			vm.masterpassData.pairingVerifier = paramObject.pairing_verifier;
			checkoutService.getMasterpassPaymentData(vm.masterpassData).then(
				function(response){
					vm.generationTime = response.generationTime;
					vm.paymentData = response.paymentData;
					app.loading = false;
				},
				function(){
					app.loading = false;
					var choice = confirm("Unable to retrieve payment data");
					if (choice){
						$state.go('cart');
					} else {
						$state.go('cart');
					}
				}
			)
   		} else {
   			app.loading = false;
			var choice = confirm("Masterpass checkout cancelled");
			if (choice){
				$state.go('cart');
			} else {
				$state.go('cart');
			}
   		}
	}

	/*function getPreCheckoutData() {

				vm.preCheckoutData = {"cards":[{"default":true,"brandName":"MasterCard","cardHolderName":"Rajesh Chakravarthy","cardId":"87fa18fa-67c7-4c0f-80e7-eeeaa48e5357","expiryYear":2022,"expiryMonth":7,"lastFour":"9283"},{"default":false,"brandName":"MasterCard","cardHolderName":"Rajesh Chakravarthy","cardId":"87fa18fa-67c7-4c0f-80e7-eeeaa48e2357","expiryYear":2022,"expiryMonth":7,"lastFour":"9999"},{"default":false,"brandName":"MasterCard","cardHolderName":"Doug Meyer","cardId":"dd5d28ec-2e86-46f9-8a3d-fb3ab60dfa85","expiryYear":2020,"expiryMonth":7,"lastFour":"3212"}],"shippingAddresses":[{"default":true,"recipientInfo":{"recipientName":"Rajesh Chakravarthy","recipientPhone":"6176583669"},"addressId":"5026f659-5840-4fa1-937e-213dd67a06c2","city":"Acton","country":"US","subdivision":"US-MA","line1":"8 Abel Jones Place","line2":null,"line3":null,"line4":null,"line5":null,"postalCode":"01720"},{"default":false,"recipientInfo":{"recipientName":"Doug Meyer test","recipientPhone":"6176583669"},"addressId":"395e5240-da54-4775-a5a6-01f1e38897d7","city":"New York","country":"US","subdivision":"US-MA","line1":"114 Fifth Avenue","line2":"Floor 11","line3":null,"line4":null,"line5":null,"postalCode":"10011"},{"default":false,"recipientInfo":{"recipientName":"Doug Meyer","recipientPhone":"6176583669"},"addressId":"395e5240-da54-4775-a5a6-01f9e38897d7","city":"New York","country":"US","subdivision":"US-MA","line1":"114 Fifth Avenue","line2":"Floor 11","line3":null,"line4":null,"line5":null,"postalCode":"10011"}],"contactInfo":{"firstName":"Rajesh","lastName":"Chakravarthy","country":"US","emailAddress":"ucmpkpi.use@gmail.com","phoneNumber":"6176583669"},"preCheckoutTransactionId":"de3f5083-e451-49c7-ad0a-3379cafb3ba1","consumerWalletId":"cd4606a428584465e7991bfc68c62ed3","walletName":"masterpass","pairingId":"8457624840ae416b084d71caf87a59ee9e084d81"};
				if(vm.preCheckoutData.cards.length == 0){
					confirm("No cards available in masterpass wallet");
					$state.go('cart');
				} else if (vm.preCheckoutData.shippingAddresses.length == 0) {
					confirm("No shippingAddresses available in masterpass wallet");
					$state.go('cart');
				}
				for(var card in vm.preCheckoutData.cards){
					if(card.default){
						vm.selectedCard = card;
						break;
					}
				}
				for(var address in vm.preCheckoutData.shippingAddresses){
					if(address.default){
						vm.selectedAddress = address;
						break;
					}
				}
				if(vm.selectedCard == null){
					vm.selectedCard = vm.preCheckoutData.cards[0];
				}
				if(vm.selectedAddress == null){
					vm.selectedAddress = vm.preCheckoutData.shippingAddresses[0];
				}
	}*/


	function getPreCheckoutData() {
		app.loading = true;
		checkoutService.getPreCheckoutData().then(
			function(response){
				vm.preCheckoutData = response;
				if(vm.preCheckoutData.cards.length == 0){
					//confirm("No cards available in masterpass wallet");
					$state.go('cart');
				} else if (vm.preCheckoutData.shippingAddresses.length == 0) {
					//confirm("No shippingAddresses available in masterpass wallet");
					$state.go('cart');
				}
				for(var card in vm.preCheckoutData.cards){
					if(card.default){
						vm.selectedCard = card;
						break;
					}
				}
				for(var address in vm.preCheckoutData.shippingAddresses){
					if(address.default){
						vm.selectedAddress = address;
						break;
					}
				}
				if(vm.selectedCard == null){
					vm.selectedCard = vm.preCheckoutData.cards[0];
				}
				if(vm.selectedAddress == null){
					vm.selectedAddress = vm.preCheckoutData.shippingAddresses[0];
				}
				app.loading = false;
			},
			function(){
				app.loading = false;
				var choice = confirm("Unable to retrieve payment data from masterpass wallet");
				if (choice){
					$state.go('cart');
				} else {
					$state.go('cart');
				}
			}
		)
	}

	function submitForm(formName) {
		$rootScope.$broadcast('makeSubmit', {formName: formName})
	}

	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}

	function createAdyenForm(order) {
		// The form element to encrypt.
		var form = document.getElementById('adyen-encrypted-form');
		var options = {};
		// Bind encryption options to the form.
		var encryptedBlobFieldName = "encryptedData";
		// The form element to encrypt.
		form.elements["generationTime"].value = vm.generationTime;
		form.elements["tokenNumber"].value = vm.paymentData.card.accountNumber;
		form.elements["holderName"].value = vm.paymentData.card.cardHolderName;
		form.elements["expiryMonth"].value = pad(vm.paymentData.card.expiryMonth,2);
		form.elements["expiryYear"].value = vm.paymentData.card.expiryYear;
                if(vm.paymentData.card.cvc != null && vm.paymentData.card.cvc != '') {
                        var input = document.createElement("input");
                        input.setAttribute("type", "text");
                        input.setAttribute("id", "cvc");
                        input.setAttribute("data-encrypted-name", "cvc");
                        form.appendChild(input);
                        // TODO: Need to figure out how to pass altIdCheckoutResp.cryptogram.de48se43Data
                        // for now pass the cvc so that adyen does not complain.
                        form.elements["cvc"].value = vm.paymentData.card.cvc;
                }

		options.name = encryptedBlobFieldName;
		options.onsubmit = function(e) {
			order.adyenEncryptedJSON = form.elements[encryptedBlobFieldName].value;
			authorizeCard(order);
			e.preventDefault();
		};
		adyen.createEncryptedForm(form, options);
		return form;
	}

	function checkForm(scope, form) {
		return scope[form].$valid;
	}

	function updateTax(){
		if(vm.channel != null){
			channel = vm.channel;
			vm.channelDetails.tax = (channel.subscriptionFee + channel.monthlyFee) * 0.05;
			vm.channelDetails.totalPrice = channel.subscriptionFee + channel.monthlyFee + vm.channelDetails.tax;
		}
	}

	function animateScrollTop(){
		$('html, body, .body').animate({
			scrollTop: 0
		}, 500);
	}

	function setCard(newCard){
		animateScrollTop();
		vm.showPrimary = true;
		vm.showCards = false;
		var cardCopy = angular.copy(newCard);
		newCard.cardId = vm.selectedCard.cardId;
		vm.selectedCard = newCard;
		$timeout(function(){
			vm.selectedCard.cardId = cardCopy.cardId;
		}, 500);
	}

	function setAddress(newAddress){
		//animateScrollTop();
		vm.showPrimaryAddress = true;
		vm.showAddresses = false;
		var addressCopy = angular.copy(newAddress);
		newAddress.addressId = vm.selectedAddress.addressId;
		vm.selectedAddress = newAddress;
		$timeout(function(){
			vm.selectedAddress.addressId = addressCopy.addressId;
		}, 500);
	}
  
	function collectBrowserInfo () {

		const screenWidth = window && window.screen ? window.screen.width : '';
		const screenHeight = window && window.screen ? window.screen.height : '';
		const colorDepth = window && window.screen ? window.screen.colorDepth : '';
		const userAgent = window && window.navigator ? window.navigator.userAgent : '';
		const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false;

		let language = '';
		if (window && window.navigator) {
			language = window.navigator.language
				? window.navigator.language
				: window.navigator.browserLanguage; // Else is for IE <+ 10
		}

		const d = new Date();
		const timeZoneOffset = d.getTimezoneOffset();

		const browserInfo = {
			screenWidth,
			screenHeight,
			colorDepth,
			userAgent,
			timeZoneOffset,
			language,
			javaEnabled,
		};

		return browserInfo;
	};

	function placeOrder(scope, order){
		var newOrder = angular.copy(order);

		if(vm.channel != null){
			newOrder.channel = vm.channel;
			newOrder.monthlyFee = vm.channel.monthlyFee;
			newOrder.subscriptionFee = vm.channel.subscriptionFee;
			newOrder.tax = vm.channelDetails.tax;
			newOrder.total = vm.channelDetails.totalPrice;
		}
		else {
			var products = cart.cartContainer.products;
			var newProducts = [];
			for(var product in products){
				products[product].productId = product;
				newProducts.push(products[product]);
			}
			newOrder.products = newProducts;
			newOrder.subtotal = cart.cartContainer.subtotal;
			newOrder.tax = cart.cartContainer.tax;
			newOrder.total = cart.cartContainer.totalPrice;
		}

		newOrder.store = "promoviehub";
		newOrder.saveCard = order.saveCard;
		newOrder.currencyCode = app.selectedCurrency.name;
		newOrder.enable3DS = vm.ThreeDSecure;
		newOrder.threeDSecureForm = vm.threeDSecureForm;
		newOrder.browserInfo = collectBrowserInfo();

		app.loading = true;

		if(vm.preCheckoutData != null){
			newOrder.masterpassCardId = vm.selectedCard.cardId;
			newOrder.masterpassShippingAddressId = vm.selectedAddress.addressId;
			newOrder.masterpassPrecheckoutTxId = vm.preCheckoutData.preCheckoutTransactionId;
			expressCheckout(newOrder);
		} else {
			newOrder.masterpassPaymentData = vm.paymentData;
			handleAuthorization(newOrder);
		}

	}

	function expressCheckout(order){
		checkoutService.expressCheckout(order).then(
			function(response) {
				vm.generationTime = response.generationTime;
				vm.paymentData = response.paymentData;
				order.masterpassPaymentData = vm.paymentData;
				handleAuthorization(order);
			},
			function(data){
				$state.go('cart');
			}
		)
	}

	function handleAuthorization(order){
		if(authorizeCard) {
			createAdyenForm(order);
			$timeout(function () {
				document.getElementById("payButton").click();
			}, 0);
		} else {
			if (vm.channel == null){
				cart.clearCart();
			}
			checkoutService.initOrder();
			accountService.init(checkoutService);
			$state.go('confirm', {order: vm.order});
		}
	}

	function authorizeCard(order){
		app.loading = true;
		checkoutService.authorizeCard(order).then(
			function(response){
				var order = response.order;
				if(order.status == "RedirectShopper") {
					// redirect shopper to issuer site
					if(response.additionalData.issuerUrl != null) {
						var choice = confirm("Please click OK to continue the processing of your 3D Secure transaction.");
						if(choice) {
							checkoutService.storeCheckoutInfo(order);
							var form = document.getElementById('adyen-threeD-redirect');
							form.elements["PaReq"].value = response.additionalData.paRequest;
							form.elements["MD"].value = response.additionalData.md;
							form.elements["TermUrl"].value = config.checkout3DSAuthUrl + "?destUrl=" + config.checkoutRedirectUrl;
							form.action = response.additionalData.issuerUrl;
							$timeout(function () {
								document.getElementById("continueButton").click();
							}, 0);
						} else {
							app.loading = false;
							$state.reload();
						}
					} else {
						alert("Issuer url not provided for 3D Secure transaction");
						app.loading = false;
						$state.reload();
					}
				} else {
					if (vm.channel == null){
						cart.clearCart();
					}
					confirmCheckout(order, true);

				}
			},
			function(data){
				if(data != null){
					alert(data.message);
				}
				confirmCheckout(order, false);
			}
		)
	}


	function confirmCheckout(order, paymentSuccessful) {

		order.oauthVerifier = vm.masterpassData.oauthVerifier;

		order.paymentSuccessful = paymentSuccessful;

		checkoutService.postback(order).then(
			function(response){
				if(paymentSuccessful){
					checkoutService.initOrder();
					accountService.init(checkoutService);
					$state.go('confirm', {order: order});
				} else {
					$state.go('cart');
				}
			},
			function(data){
				//alert("Masterpass postback failed");
				if(paymentSuccessful){
					checkoutService.initOrder();
					accountService.init(checkoutService);
					$state.go('confirm', {order: order});
				} else {
					$state.go('cart');
				}
			}
		)

	}

};

module.exports = function (module) {
	var name = 'MasterpassCheckoutController';
	module.controller(name, controller);

	return name;
};
