'use strict';

var controller = function ($state, $scope, checkoutService, accountService, cart, config, logger, textBundle) {
	logger('topNavbar controller');

	/* declaration */

	this.cart = cart;
	this.state = $state;
	this.accountService = accountService;
	this.goToMovies = goToMovies;
	this.switchStoreUrl = config.switchStoreUrl;

	var vm = this;

	/* initialization */

	/* implementation */

	function goToMovies(){
		$state.go("account", {tab: 3}, {reload: true});
	}
};

module.exports = function (module) {
	var name = 'TopNavbarController';
	module.controller(name, controller);

	return name;
};
