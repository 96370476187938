'use strict';

var controller = function ($state, $window, $timeout, $rootScope, app, cart, accountService, fpanCheckoutService, config, logger, textBundle) {
	logger('review controller');

  var vm = this;

	this.config = config;

	this.accountService = accountService;

	this.info = fpanCheckoutService.getCheckoutInfo(); // Retrieve previously entered user info from local storage

	this.app = app;

	this.cart = cart;

	this.isDigital = cart.cartContainer.digital;

	this.placeOrder = placeOrder;

	this.generateCardBrandingImage = fpanCheckoutService.generateCardBrandingImage;

	this.isMixed = cart.checkMixed();

	if(this.info.id) {
		accountService.getOrder(this.info.id).then(
			function(currOrder){
				if(currOrder && currOrder.status == "Authorised"){
					cart.clearCart();
					fpanCheckoutService.clearCheckoutInfo();
					fpanCheckoutService.initOrder();
					accountService.init(fpanCheckoutService);
					$state.go('confirm', {order: currOrder});
					return;
				} else {
					$window.scrollTo(0,0);
					fpanCheckoutService.initializeReview();
				}
			},
			function(data){
				if(data != null){
					alert(data.message);
				}
				app.loading = false;
				$window.scrollTo(0,0);
				fpanCheckoutService.initializeReview();
			}
		);
	}

	// If cart is empty, redirect to empty cart
	if(Object.keys(this.info).length <= 2){
		$state.go('cart');
		return;
	}

	/* implementation */

	function createAdyenForm(order) {
		// The form element to encrypt.
		var form = document.getElementById('adyen-encrypted-form');
		var options = {};
		// Bind encryption options to the form.
		var encryptedBlobFieldName = "encryptedData";
		// The form element to encrypt.
		var form = document.getElementById('adyen-encrypted-form');
		var cardInfo;
		form.elements["generationTime"].value = (new Date()).toISOString();
		form.elements["holderName"].value = order.card.name;
		form.elements["tokenNumber"].value = order.card.cardNumber;
		form.elements["expiryMonth"].value = pad(order.card.month, 2);
		form.elements["expiryYear"].value = pad(order.card.year, 4);
		if(order.card.cvv != null) {
			var input = document.createElement("input");
			input.setAttribute("type", "text");
			input.setAttribute("id", "cvc");
			input.setAttribute("data-encrypted-name", "cvc");
			form.appendChild(input);
			form.elements["cvc"].value = order.card.cvv;
		}

		options.name = encryptedBlobFieldName;
		options.onsubmit = function(e) {
			order.adyenEncryptedJSON = form.elements[encryptedBlobFieldName].value;
			e.preventDefault();
		};
		adyen.createEncryptedForm(form, options);
		return form;
	}

	function placeOrder(){
		var newOrder = angular.copy(fpanCheckoutService.order);
		var products = cart.cartContainer.products;
		var newProducts = [];
		for(var product in products){
			products[product].productId = product;
			newProducts.push(products[product]);
		}
		newOrder.products = newProducts;
		newOrder.subtotal = cart.cartContainer.subtotal;
		newOrder.tax = cart.cartContainer.tax;
		newOrder.total = cart.cartContainer.totalPrice;
		newOrder.card.cardNumber = this.info._creditCardNumber;
		newOrder.card.cvv = this.info._cvv;
		newOrder.card.month = this.info.expMonth;
		newOrder.card.year = this.info.expYear;
		newOrder.card.name = this.info.fullName;
		newOrder.card.type = this.info.cardType;

		newOrder.currencyCode = app.selectedCurrency.name;

		newOrder.enable3DS = this.info.threeDSecure;

		app.loading = true;
		createAdyenForm(newOrder);
		$timeout(function () {
			document.getElementById("payButton").click();
		}, 0);

		$timeout(function () {
			// don't send raw fpan details to the server
			newOrder.card.cardNumber = '************' + newOrder.card.cardNumber.slice(newOrder.card.cardNumber.length - 4);
			newOrder.card.cvv = '***';
			fpanCheckoutService.placeOrder(newOrder).then(
				function(response){
					app.loading = false;
					vm.order = response.order;
					if(vm.order.status == "RedirectShopper") {
						// redirect shopper to issuer site
						if(response.additionalData.issuerUrl != null) {
							var choice = confirm("Please click OK to continue the processing of your 3D Secure transaction.");
							if(choice) {
								fpanCheckoutService.storeCheckoutInfo(vm.order);
								var form = document.getElementById('adyen-threeD-redirect');
								form["PaReq"].value = response.additionalData.paRequest;
								form["MD"].value = response.additionalData.md;
								form["TermUrl"].value = config.fpan3DSAuthUrl + "?orderId=" + vm.order.id + "&destUrl=" + window.location.href;
								form.action = response.additionalData.issuerUrl;
								$timeout(function () {
									document.getElementById("continueButton").click();
								}, 0);
							} else {
								app.loading = false;
								$state.reload();
							}
						} else {
							alert("Issuer url not provided for 3D Secure transaction");
							app.loading = false;
							$state.reload();
						}
					} else {
						cart.clearCart();
						fpanCheckoutService.clearCheckoutInfo();
						fpanCheckoutService.initOrder();
						accountService.init(fpanCheckoutService);
						$state.go('confirm', {order: vm.order});
					}
				},
				function(data){
					app.loading = false;
					if(data != null){
						alert(data.message);
					}
					$state.reload();
				}
			);
		}, 100);
	}

	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}
};

module.exports = function (module) {
	var name = 'ReviewController';
	module.controller(name, controller);

	return name;
};
