'use strict';
var angular = require('angular');
var testingService = angular.module('testingServiceProvider', []);


var factory = function (config, $q, $http) {

	var url = config.authBase + "/mdes";

	var instance = {

		transactTest: function(tuid) {
			return $http({
					method: 'GET',
					url: url + '/test7?tuid=' + tuid,
					withCredentials: true
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		},

		getTokens: function() {
			return $http({
					method: 'GET',
					url: url + '/getAccountTokens',
					withCredentials: true
				})
				.then(
					function(response){
						return response.data;
					},
					function(errorResponse){
						return $q.reject(errorResponse.data);
					}
				);
		}
	};

	return instance;
};

testingService.factory('testingService', ['config', '$q', '$http', factory]);
module.exports = testingService.name;
