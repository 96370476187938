'use strict';

var controller = function ($state, $window, cart, app, checkoutService, localStorageService, logger, textBundle, order) {
	logger('confirm controller');

	/* declaration */

	var vm = this;

	this.order = order;
	this.app = app;

	/* initialization */

	if(!order){
		$state.go('cart');
	}

	/* implementation */

};

module.exports = function (module) {
	var name = 'ConfirmController';
	module.controller(name, controller);

	return name;
};