'use strict';

var controller = function ($state, $window, $timeout, $rootScope, app, cart, channel, fpanCheckoutService, accountService, config, logger, textBundle) {
	logger('fpanCheckout controller');

	/* declaration */

	// Retrieve any existing checkout info
	this.info = fpanCheckoutService.getCheckoutInfo();

	this.review = review;
	this.config = config;
	this.checkPattern = checkPattern;
	this.detectCardType = detectCardType;

	// Regexes used to check user input
	this.nameRegex = /^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/i;
	this.emailRegex = /\S+@\S+\.\S+/;
	this.addressRegex = /^[a-zA-Z0-9\s\,\''\-]*$/;
	this.phoneNumberRegex = /1?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/;
	this.creditCardRegex = /(?:\d[ -]?){12,18}\d/;
	this.cvvRegex = /^[0-9]{3,4}$/;
	this.isDigital = cart.cartContainer.digital;
	this.billingStateRequired = true;
	this.accountService = accountService;
	this.shippingStateRequired = true;
	this.threeDSecure = false;
	this.accountService = accountService;

	this.years = [];

	/* initialization */

	$window.scrollTo(0,0);

	// If cart is empty, redirect to empty cart
	if(cart.cartContainer['cartCount'] == 0){
		$state.go('cart');
	}

	// Populate year list for year dropdown
	var currentTime = new Date();
	var minYear = currentTime.getFullYear();
	var maxYear = currentTime.getFullYear() + 25;
	for(minYear; minYear < maxYear; minYear++){
		this.years.push(minYear);
	}



	/* implementation */

	//TODO: move out to service?
	function review(){

		// clear unused values for normal checkout
		this.info['shippingAddress3'] = null;
		this.info['billingAddress3'] = null;
		this.info['shippingPhone'] = null;
		this.info['masterpassCheckoutResource'] = null;
		this.info['shippingRecipient'] = this.info['fullName']

		// Validate form
		var forgottenFields = [];
		var requiredFields = {};
		requiredFields.fullname = this.info['fullName'];
		requiredFields.email = this.info['email'];
		requiredFields.address1 = this.info['billingAddress1'];
		requiredFields.phone = this.info['phone'];
		requiredFields.zipcode = this.info['billingZipcode'];
		requiredFields.state = this.info['billingState'];
		requiredFields.city = this.info['billingCity'];
		requiredFields.country = this.info['billingCountry'];
		requiredFields.creditCardNumber = this.info['creditCardNumber'];
		requiredFields.expMonth = this.info['expMonth'];
		requiredFields.expYear = this.info['expYear'];
		// cvv is optional - requiredFields.cvv = this.info['cvv'];
		if(this.info['shippingSameAsBilling']){
			// Copy shipping address to billing address
			this.info['shippingAddress1'] = this.info['billingAddress1'];
			this.info['shippingAddress2'] = this.info['billingAddress2'];
			this.info['shippingZipcode'] = this.info['billingZipcode'];
			this.info['shippingCity'] = this.info['billingCity'];
			this.info['shippingState'] = this.info['billingState'];
			this.info['shippingCountry'] = this.info['billingCountry'];
		}
		requiredFields.shippingAddress1 = this.info['shippingAddress1'];
		requiredFields.shippingZipcode = this.info['shippingZipcode'];
		requiredFields.shippingCity = this.info['shippingCity'];
		requiredFields.shippingState = this.info['shippingState'];
		requiredFields.shippingCountry = this.info['shippingCountry'];

		if(requiredFields.shippingState.length > 12){
			alert('State/Prov Code cannot exceed 12 char');
			return;
		}

		// Add any empty fields to forgotten field list
		for(var key in requiredFields){
			if(requiredFields[key] == null){
				forgottenFields.push(key);
			}
		}
		// Alert user if they forgot to enter anything
		// Otherwise censor credit card info and go to review page
		console.log('forgottenFields:' + forgottenFields);
		if(forgottenFields.length == 0){
			this.info['cardType'] = detectCardType(this.info['creditCardNumber']);
			this.info['_cvv'] = this.info['cvv'];
			this.info['_creditCardNumber'] = this.info['creditCardNumber']
			this.info['cvv'] = "***";
			this.info['creditCardNumber'] = "************" + this.info['creditCardNumber'].slice(-4);

			// save entered information
			fpanCheckoutService.storeCheckoutInfo(this.info);

			$state.go("review");
			return;
		} else {
			alert('Please fill in required fields: ' + forgottenFields);
		}

		// save entered information
		fpanCheckoutService.storeCheckoutInfo(this.info);
	};

	function checkPattern(pattern){
		if(pattern != undefined){
			return decodeURIComponent(pattern);
		}
		else {
			return null;
		}
	}

	function detectCardType(number){
		// maestro
		if (/^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/.test(number)){
			return "maestro";
		}

		// mastercard
		if (/^5[1-5][0-9]{5,}$/.test(number)){
			return "master";
		}

		// visa
		if (/^4[0-9]{6,}$/.test(number)){
			return "visa";
		}

		// amex
		if (/^3[47][0-9]{5,}$/.test(number)){
			return "amex";
		}

		// diners
		if (/^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/.test(number)){
			return "diners";
		}

		// discover
		if (/^6(?:011|5[0-9]{2})[0-9]{3,}$/.test(number)){
			return "discover";
		}

		// unknown
		return null;
	}


};

module.exports = function (module) {
	var name = 'fpanCheckoutController';
	module.controller(name, controller);

	return name;
};
