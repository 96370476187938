'use strict';

var controller = function ($state, $stateParams, facets, logger, textBundle, $timeout, categoryService) {
	logger('products controller');

	/* declaration */

	this.stateParams = $stateParams;

	this.state = $state;

	this.sort = $stateParams.sort;

	this.facets = facets;

	this.toggleFilterDrawer = toggleFilterDrawer;

	this.resetFilters = resetFilters;

	this.showProperies = "";

	this.toggleProperties = toggleProperties;

	this.addOrRemoveProperty = addOrRemoveProperty;

	this.checkProperties = checkProperties;

	this.setSort = setSort;

	this.propertyList = null;

	this.showProperties = 'genre';

	this.applySort = applySort;

	this.toggleFacet = toggleFacet;

	this.navigateToState = navigateToState;

	this.categoryService = categoryService;

	/* initalization */

	if($stateParams.facets){
		this.propertyList = $stateParams.facets.split(',');
	}
	else {
		this.propertyList = [];
	}



	/* implementation */

	function applySort(sortKey){
		this.setSort(sortKey);
		this.navigateToState()
	}

	function toggleFilterDrawer() {
		// Slide in animation for filter sidebar
		if($('.refine').hasClass('outside')){
			$('.refine').toggleClass('inside');
			$('.refine').toggleClass('outside');
			$timeout(function() {
				$('.sidebar2-offcanvas').toggleClass('active');
				$('.productList').toggleClass('compressed');
			}, 250);
		}
		else if($('.refine').hasClass('inside')) {
			$('.sidebar2-offcanvas').toggleClass('active');
			$('.productList').toggleClass('compressed');
			$timeout(function() {
				$('.refine').toggleClass('inside');
				$('.refine').toggleClass('outside');
			}, 250);
		}
	}

	function resetFilters(){
		// clear filtering and sorting
		this.propertyList = [];
		this.sort = ''
		this.size = null
		// go to state and close filter drawer
		this.navigateToState(this.toggleFilterDrawer.bind(this));
	}

	function toggleProperties(facet){
		// Set show properties to clicked facet
		// This displays all filters related to that facet
		if(this.showProperties == facet){
			this.showProperties = "";
		}
		else {
			this.showProperties = facet;
		}
	}

	function addOrRemoveProperty(key, property){
		// Check if property in list of facets which are currently being used

		var propertyFull = key + ":" + property;
		if(this.propertyList.indexOf(propertyFull) == -1){
			this.propertyList.push(propertyFull);
		}
		else {
			this.propertyList.splice(this.propertyList.indexOf(propertyFull), 1);
		}
	}

	function checkProperties(key, property){
		// Check if property in list of facets which are currently being used
		var propertyFull = key + ":" + encodeURIComponent(property);
		if(this.propertyList.indexOf(propertyFull) == -1){
			return false;
		}
		else {
			return true;
		}
	}

	function setSort(sort){
		if(this.sort == sort){
			if(this.sort.split(',')[1] == 'desc'){
				this.sort = this.sort.split(',')[0];
			}
			else {
				this.sort = sort + ',desc';
			}
		}
		else {
			this.sort = sort;
		}
	}

	function toggleFacet(facetKey, facetValue){
		this.addOrRemoveProperty(facetKey, encodeURIComponent(facetValue));
		this.navigateToState();
	}

	function navigateToState(callback){
		var promise = this.state.go(
			'products.productListing',
			{
				catId: this.stateParams.catId,
				size: this.stateParams.size,
				sort: this.sort,
				page: null,
				searchTerms: this.stateParams.searchTerms,
				facets: this.propertyList.join()
			},
			{reload: 'products.productListing'}
		)
		if(callback){
			promise.then(callback)
		}
	}
};

module.exports = function (module) {
	var name = 'ProductsController';
	module.controller(name, controller);

	return name;
};