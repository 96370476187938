'use strict';
var angular = require('angular');
var ngResource = require('angular-resource');

var userSessionStore = angular.module('userSessionStore', [ngResource]);

userSessionStore.factory('userSessionStore', function (logger) {

	var userSession = [];

	return {
		getState: function () {
			return userSession;
		},

		get: function (key) {
			return userSession[key];
		},

		set: function (key, value) {
			userSession[key] = value;
		},

		remove: function (key) {
			userSession[key] = null;
		}
	}
});

module.exports = userSessionStore.name;