'use strict';

var controller = function ($state, categoryService, accountService, app, logger, config) {
	logger('bottomNavbar controller');
	this.state = $state;

	this.app = app;

	this.categoryService = categoryService;

	this.accountService = accountService;

	this.encode = encode;

	this.genres = categoryService.categoryList;

	this.currencies = config.currencies;

	function chunk (arr, len) {
	  var chunks = [],
	      i = 0,
	      n = arr.length;

	  while (i < n) {
	    chunks.push(arr.slice(i, i += len));
	  }

	  return chunks;
	}

	function encode(uriComponent){
		return encodeURIComponent(uriComponent);
	}
};

module.exports = function (module) {
	var name = 'BottomNavbarController';
	module.controller(name, controller);

	return name;
};
