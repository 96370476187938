'use strict';

var controller = function ($scope, $state, $stateParams, $window, app, catalog, categoryPath, categoryService, logger, textBundle) {
	logger('productListing controller');

	/* declaration */

	this.catalog = catalog;
	this.stateParams = $stateParams;
	this.state = $state;
	this.app = app;

	this.goToPage = goToPage;
	this.nextPage = nextPage;
	this.prevPage = prevPage;

	/* initalization */

	categoryService.currentCategory = $stateParams.catId;

	if(categoryPath != null){
		categoryService.categoryPath = Object.keys(categoryPath.toJSON()).map(function (key) {return categoryPath.toJSON()[key]}).reverse();
	}

	/* implementation */

	function goToPage(pageNum){
		var pageNum = Number(pageNum) || 0

		if(pageNum > 0 && pageNum != this.catalog.pageInfo.number + 1){
			this.state.go(
				'products.productListing',
				{
					catId: this.stateParams.catId,
					page: pageNum - 1,
					size: this.stateParams.size,
					sort: this.stateParams.sort,
					searchTerms: this.stateParams.searchTerms,
					facets: this.stateParams.facets
				},
				{
					reload: 'products.productListing'
				}
			)
		}
	}

	function nextPage(){
		var currentPage = this.catalog.pageInfo.number + 1
		this.goToPage(currentPage + 1)
	}

	function prevPage(){
		var currentPage = this.catalog.pageInfo.number + 1
		this.goToPage(currentPage - 1)
	}
};


module.exports = function (module) {
	var name = 'ProductListingController';
	module.controller(name, controller);

	return name;
};