'use strict';

var controller = function ($state, $stateParams, $rootScope, $urlRouter, $window, $timeout, $location, app, FacetData, categoryService, config, logger, textBundle, userSessionStore, accountService, checkoutService) {

	logger('app controller');

	/* declaration */

	var vm = this;

	this.app = app;

	this.state = $state;

	this.stateParams = $stateParams;

	this.selectLanguage = selectLanguage;

	this.noScroll = noScroll;

	FacetData.get(function(data){
		categoryService.categoryList = data.genre;
	});

	this.config = config;

	this.nextState = '';

	this.previousState = '';

	this.browserControl = false;

	/* initialization */

	// setting default
	textBundle.getBundle(app.selectedLanguage).then(
		setTextBundle.bind(this)
	);

	// bind page navigation started event
	$window.addEventListener("beforeunload", function(){
		$window.ignoreLoadError = true
	}, false);

	/* implementation */

	function setTextBundle(result) {
		this.textBundle = result;
		logger(this.textBundle);
	};

	function selectLanguage() {
		logger('Language is changed to ' + app.selectedLanguage);

		userSessionStore.set('selectedLanguage', app.selectedLanguage);

		textBundle.getBundle(app.selectedLanguage).then(
			setTextBundle.bind(this)
		);
	};

	$rootScope.$on('$stateChangeStart', function(event, to, toParams, from, fromParams) {
		app.loading = true;
		vm.nextState = to.name;
		vm.previousState = from.name;
		accountService.init(checkoutService);
	});

	$rootScope.$on('$stateChangeSuccess', function(event, to, toParams, from, fromParams) {
		app.loading = false;
		if(to.name != 'account'){
			window.scrollTo(0, 0);
		}
	});


	function noScroll(){
		return app.loading || app.showConfiguration || app.showTrace;
	};
};

module.exports = function (module) {
	var name = 'appController';
	module.controller(name, controller);
	return name;
};
